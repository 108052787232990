import React, { useState, useEffect, useRef } from "react";
import { DropLIstProps } from "../interfaces/DropList.interface";
import { useLogout } from "@hilma/auth";
import useClick from "../hooks/useOnClickOutside";
import "../styles/dropList.scss";


export const DropList: React.FC<DropLIstProps> = ({ open, closeDropList, exceptRef }: DropLIstProps) => {
  const [showList, setShow] = useState<boolean>(open);
  const DropListRef = useRef<HTMLDivElement>(null);
  const list = useRef<HTMLDivElement>(null);
  const logout = useLogout();

  useEffect(() => {
    setShow(open);
  }, [open])

  // animation to open the drop list
  useEffect(() => {
    if (!list.current) return;
    if (showList === true) {
      list.current.style.height = '2rem'
    } else {
      list.current.style.height = `0px`;
    }
    // eslint-disable-next-line
  }, [showList, list]);

  // detecting if there are any clicks that are not on the input
  useClick(DropListRef, () => {
    if (!showList) return;
    closeDropList();
  }, exceptRef);

  return (
    <div className="license-drop-container" ref={DropListRef}>

      <div className="license-drop-list" ref={list}>

        <span>
          <div
            className="license-drop-list--item"
            id="list"
            onClick={() => logout()}
          >
            התנתק
            </div>
        </span>
      </div>
    </div>
  );
}
