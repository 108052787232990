import { DetailsProps, errorType, formObject } from '../consts/validation'
import { Button, FormField, CheckBox } from './FormAttributes';
import { fields, ADD_EDIT_BUTTONS } from '../consts/addEditOrganizationFields'
import React from 'react'

const AddEditOrganizationDetails: React.FC<DetailsProps> = ({ formValues, errors, autoChange, onChange, mode, disabled, deleteError, onBlur, sendMail }) => {
    
    return (
        <div className="AddEditOrgForm AddEditOrgDetails">
            {fields.map(field =>
                field.id === "autoActive" ?
                    <CheckBox
                        key={field.id}
                        id={field.id}
                        name={field.fieldLabel}
                        onChange={autoChange}
                        value={formValues.autoActive}
                    />
                    :
                    <FormField
                        key={field.id}
                        id={field.id}
                        value={String(formValues[field.id as keyof formObject])}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeHolder={field.fieldLabel}
                        error={errors[field.id as keyof errorType] || ""}
                        disabled={disabled}
                        type={field.id === "autoActive" ? "boolean" : "text"}
                        fieldLabel={field.fieldLabel}
                        icon={null}
                        />
            )}

            <div className="bottomButton">
                {
                    ADD_EDIT_BUTTONS.map(({text, type}) => 
                        <Button key={text} text={text} disabled={sendMail?.disabledMail[type]} onClick={() => {sendMail?.onEmailClick(type)}} />                        
                    )
                }
            </div>
        </div>

    )
}

export default AddEditOrganizationDetails