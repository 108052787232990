import React, { useMemo } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { TABS } from '../consts/tabs'
import '../styles/tabs.scss'

const Tabs: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const currentPath = useMemo(() => props.location.pathname, [props.location.pathname]);
    
    return (
        <div className="tabs">
            <ul className="tab-links">
                {TABS.map(tab =>
                    <Link to={tab.path} key={tab.path}>
                        <li className={currentPath.includes(tab.path) || currentPath === tab.anotherPath ? "boldTopBar" : ""}>{tab.tabName}</li>
                    </Link>
                )}
            </ul>
        </div>
    )
}
export default withRouter(Tabs)