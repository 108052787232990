import { makeStyles, createMuiTheme } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    input: {
        padding: "1.2vh 2vw",
        fontSize: "2vh",
        color: "#a5a4bf"

    },
    iconButton: {
        fontSize: "1.8vh",
        color: "#a5a4bf"
    },
}))

export const theme = createMuiTheme({
    direction: "rtl",
    palette: {
        primary: {
            main: "#2a3474"
        }
    }
})