import React, { useMemo } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { useStyles, theme } from '../consts/dayPicker'
import { DatePicketProps } from '../interfaces/DayPicker.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { create } from "jss"
import DateFnsUtils from '@date-io/date-fns';
import heLocale from "date-fns/locale/he";
import rtl from "jss-rtl";


const OurDayPicker: React.FC<DatePicketProps> = (props: DatePicketProps) => {
    const classes = useStyles();
    
    const jss = useMemo(() => create({ plugins: [...jssPreset().plugins, rtl()] }), [])

    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={heLocale} >
                    <KeyboardDatePicker
                        minDate={props.date && props.date < new Date(Date.now()) ? props.date : new Date(Date.now())}
                        maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                        className="form--input date-picker-inline"
                        disabled={props.disabled}
                        style={{ padding: "0 0" }}
                        InputProps={{ disableUnderline: true}}
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        inputProps={{ readOnly: true, className: classes.input }}
                        id="date-picker-inline"
                        keyboardIcon={<><FontAwesomeIcon icon={["fas", "calendar-day"]} className={classes.iconButton} /></>}
                        value={props.date}
                        onChange={value => props.setDate(value)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </StylesProvider>

    )
}

export default OurDayPicker