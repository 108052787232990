import { RouteComponentProps } from 'react-router';

export interface organizationProps extends RouteComponentProps {
    location: Location & {
        state: { projectName: string, projectId?: number }
    }
}

export enum LicenseNames {
    PRIVATE = "private",
    INSTITUTIONAL = "institutional",
    CUSTOMIZED = "customized"
}

export interface organizationInterface {
    id: number,
    full_name: string,
    given_number_of_licenses: number,
    expire_date: string,
    license_type: LicenseNames
}

export interface organizationsResInterface {
    licenses: organizationInterface[],
    licensesLength: number,
}