import { createBrowserHistory } from 'history';
import axios from 'axios';

export const history = createBrowserHistory()

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    const { status, data } = err.response;
    if (status === 403 && data.error === "Forbidden") {
        history.push('/forbidden-request');
        return new Promise(() => {});
    } else return Promise.reject(err);
  }
);