import React, { useState, useContext, useEffect, ChangeEvent } from 'react';
import { SettingForms, Inputs, Psw, typesOfSetting, emptyForm, emptyPassword } from '../consts/settings'
import { ERRORS, REGEX } from '../consts/validation';
import { AlertContext } from "@yaeledri/use-alert"
import { SettingForm } from '../components/SettingForm';
import { useUser } from '@hilma/auth';
import PopUp from '../components/PopUp';
import Axios from 'axios';
import '../styles/settings.scss';

const Settings = () => {
    const [formInputs, setFormInputs] = useState<Inputs>(JSON.parse(JSON.stringify(emptyForm)));
    const [errors, setErrors] = useState<Inputs>(JSON.parse(JSON.stringify(emptyForm)));
    const [showPsw, setShowPsw] = useState<Psw>(JSON.parse(JSON.stringify(emptyPassword)));
    const [loading, setLoading] = useState<typesOfSetting | "">("");
    const [popup, setPopup] = useState<boolean>(false);

    const { createAlert } = useContext(AlertContext);
    const user = useUser();

    // get the user info
    useEffect(() => {
        const { name, username, phone } = user || formInputs;
        setFormInputs(prev => { return { ...prev, username, name, phone } });
        // eslint-disable-next-line
    }, [user])

    // when changing inputs
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setFormInputs(prev => { return { ...prev, [name]: value } });
        setErrors(prev => { return { ...prev, [name]: "" } })
    }

    // when blurring from an input
    const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        if (value === "") {
            setErrors(prev => { return { ...prev, [name]: 'השדה חייב להכיל ערך' } });
            return
        }

        switch (name) {
            case "username":
                if (!(REGEX.email).test(value)) onBlurErrors(name as keyof Inputs, ERRORS.email);
                break
            case 'name':
                if (!(REGEX[name]).test(value)) onBlurErrors(name as keyof Inputs, ERRORS.nameOnlyLetters);
                break
            case 'phone':
                if (!(REGEX.numbersOnly).test(value)) onBlurErrors(name as keyof Inputs, ERRORS.phoneRightCharacters);
                if (!(REGEX[name]).test(value)) onBlurErrors(name as keyof Inputs, ERRORS.phoneFormat);
                if (value.length !== 9) onBlurErrors(name as keyof Inputs, ERRORS.phoneLength);
                break
            default: //means all the password fields
                if (value.length > 20 || value.length < 8) onBlurErrors(name as keyof Inputs, ERRORS.passwordLength);
                if (!(REGEX.password).test(value)) onBlurErrors(name as keyof Inputs, ERRORS.password);
                break
        }
    }

    // error function for on blur
    const onBlurErrors = (name: keyof Inputs, error: string) => {
        setErrors(prev => { return { ...prev, [name]: error } });
    }

    // changing show state of a password input
    const changeShowPsw = (name: keyof Inputs, status: boolean) => {
        if (name === "username") return;
        setShowPsw(prev => { return { ...prev, [name]: status } });
    }

    // check if the data is valid in order to send him
    const canSendRequest = (name: typesOfSetting): boolean => {
        if (name === "psw") {
            const { psw, newpsw, verifyPsw } = formInputs;
            if (psw === "" || newpsw !== verifyPsw) {
                if (newpsw !== verifyPsw) {
                    setErrors(prev => { return { ...prev, verifyPsw: ERRORS.passwordAndVerifyNotTheSame } })
                }
                return false
            }
            else return true;
        } else {
            const value = formInputs[name];
            return value !== "" && errors[name] === "" && value !== user?.[name];
        }
    }

    // requests errors handling
    const handleError = (msg: string) => {
        switch (msg) {
            case "PSW_NOT_MATCH":
                createAlert(ERRORS.newPasswordNotInFormat);
                break
            case "INVALID_NAME":
                createAlert(ERRORS.newUsernameNotInFormat)
                break
            case "INVALID_PHONE":
                createAlert(ERRORS.newPhoneNotInFormat)
                break;
            case "CookieDidNotChanged":
                createAlert(ERRORS.cookieProblem)
                break;
            case 'PSW_VERIFY_NOT_MATCH':
                createAlert(ERRORS.passwordAndVerifyNotTheSame);
                break
            case 'PSW_WRONG':
                createAlert(ERRORS.wrongPassword);
                break;
            case "INVALID_EMAIL":
                createAlert(ERRORS.wrongEmail);
                break
            default:
                createAlert(ERRORS.generalUpdateError);
                break
        }
    }

    // send save request to the server for each setting form
    const save = async (nameOfInput: typesOfSetting) => {
        const valid = canSendRequest(nameOfInput);
        if (!valid) {
            setPopup(true);
            return;
        }
        setLoading(nameOfInput);
        const { psw, username, newpsw, verifyPsw, name, phone } = formInputs;
        let bodyToSend;

        if (nameOfInput === "psw") {
            bodyToSend = {
                password: psw,
                newpsw,
                confirmpsw: verifyPsw
            }
        } else if (nameOfInput === "name") bodyToSend = { name };
        else if (nameOfInput === "phone") bodyToSend = { phone: '+972'.concat(phone) };
        else if (nameOfInput === 'username') bodyToSend = { email: username };

        try {
            await Axios.post(`/api/admin/change-${nameOfInput || ""}`, bodyToSend);
            createAlert('עדכון הפרטים בוצע בהצלחה', 'success');
            if (nameOfInput === "psw") {
                setFormInputs(prev => { return { ...prev, psw: "", newpsw: "", verifyPsw: "" } })
            } else if (nameOfInput !== 'phone') {
                if (nameOfInput === 'username') user[nameOfInput] = bodyToSend['email'];
                else user[nameOfInput] = bodyToSend[nameOfInput];
            } else {
                user[nameOfInput] = phone;
            }
        } catch (error: any) {
            handleError(error?.data?.error || error?.data?.message?.[0]);
        }
        setLoading("");
    }

    return <div className="setting">

        {SettingForms.map(form =>
            <React.Fragment key={form.saveArg}>
                <SettingForm
                    fields={form.fields}
                    onChange={onChange}
                    title={form.title}
                    save={save}
                    onBlur={onBlur}
                    saveArg={form.saveArg}
                    values={formInputs}
                    disabled={form.fields.every(field => formInputs[field.name] === "" || formInputs[field.name] === user?.[field.name])}
                    errors={errors}
                    loading={loading}
                    showPsw={form.saveArg === 'psw' ? showPsw : undefined}
                    setShowPsw={form.saveArg === 'psw' ? changeShowPsw : undefined}
                />
            </React.Fragment>
        )}

        <PopUp open={popup} close={() => { setPopup(false) }} title={"ישנם שדות לא תקינים"}>
            <div>
                אנא תקנו את המידע הנחוץ על מנת שתוכלו לשנות את הפרטים
            </div>
        </PopUp>
    </div>
}
export default Settings;