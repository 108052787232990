import React, { useState, useEffect, useContext } from 'react';
import { EMPTY_RESTORE_FORM, LOGIN_ERRORS } from '../consts/loginConsts';
import { useHistory, useLocation } from 'react-router-dom'
import { AlertContext } from '@yaeledri/use-alert';
import { FullLoading } from '../components/FullLoading';
import { LoginForm } from '../components/LoginForm';
import { loginForm } from '../interfaces/Login.interface';
import { NotFound } from '../components/NotFound';
import { TopBar } from '../components/TopBar';
import queryString from 'query-string';
import errorImg from '../media/error.png'
import Axios from 'axios';
import '../styles/restore.scss';

export const Restore: React.FC = () => {
    const [form, setForm] = useState<loginForm>(EMPTY_RESTORE_FORM);
    const [token, setToken] = useState<string>("token");
    const [email, setEmail] = useState<string>("email");
    const [loading, setLoading] = useState<boolean>(false);

    const { createAlert } = useContext(AlertContext);
    const history = useHistory();
    const location = useLocation()

    // setting state values
    useEffect(() => {
        const { email: emailFromSearch, token: tokenFromSearch } = queryString.parse(location.search) || {};
        setToken(tokenFromSearch);
        setEmail(emailFromSearch);
    }, [location])

    // generic function for validation to user-psw
    const validate = () => {
        let valid = true
        Object.keys(form).map(field => {
            let err = "";
            const value = form[field].value;
            if (field === 'psw') {
                if (value === "") err = LOGIN_ERRORS.noPsw;
                else if (value.length < 6) err = LOGIN_ERRORS.shortPsw;
                else if (value.length > 20) err = LOGIN_ERRORS.longPsw;
            }
            if (field === 'verifyPsw') {
                if (value !== form.psw?.value) err = LOGIN_ERRORS.pswDoesntMatches;
            }

            if (err) {
                createAlert(err);
                valid = false;
            }
            return err;
        })
        return valid;
    }

    // function for handling the errors in the server requests
    const errorHandler = (msg: any) => {
        switch (msg?.status) {
            case 500:
                createAlert(LOGIN_ERRORS.tryLater);
                break;
            case 401:
                if (msg?.data?.key === "PassDosentMatch") createAlert(LOGIN_ERRORS.wrongPsw);
                else if (msg?.data?.key === "NoUsername") createAlert(LOGIN_ERRORS.wrongUsername);
                else if (msg?.data?.key === "UserIsBlocked") createAlert(LOGIN_ERRORS.userBlocked)
                else createAlert(LOGIN_ERRORS.tryLater)
                break;
            case 400:
                if (msg?.data?.error === "NoPhone") createAlert(LOGIN_ERRORS.noPhone);
                else createAlert(LOGIN_ERRORS.tryLater);
                break;
            default:
                createAlert(LOGIN_ERRORS.tryLater);
                break;
        }
    }

    // function to send request to the server
    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (!validate()) return;
        setLoading(true);
        try {
            await Axios.post('/api/admin/reset-psw',
                {
                    token,
                    username: email,
                    password: form.psw?.value || ""
                }
            )
            backToHome()
        }
        catch (error) {
            console.log('error: ', error);
            errorHandler(error);
        }
        setLoading(false);
    }

    // go to projects page
    const backToHome = () => history.push('/');

    return (
        <div className='login-component container'>

            <TopBar isLoginPage />

            {!email || !token ?
                <div className='not-found'>
                    <NotFound img={errorImg} text="שגיאה! הקישור לא תקף" />
                </div> :
                <div className='form'>
                    <span className="title restore">רשיונות הילמה</span>

                    <LoginForm inputsObj={form} setForm={setForm} />

                    <button onClick={handleSubmit} className='submit-input' disabled={loading}>שחזר סיסמה</button>

                    <div onClick={backToHome}>משתמש רשום? <span className='link'>התחבר כאן</span></div>

                    <FullLoading open={loading} />
                </div>

            }

        </div>
    )
}