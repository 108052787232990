import React from 'react';
import { GenericPopUp } from './GenericPopUp';
import { PopUpProps } from '../interfaces/PopUp.interfaces'
import { Button } from './FormAttributes';
import "../styles/popUp.scss";

const PopUp: React.FC<PopUpProps> = ({ close, keep, deleteing, title, children, open, loading }: PopUpProps) => {

    return (
        <GenericPopUp
            open={open}
            close={close}
            middleOfTop={
                <div className="title">
                    {title}
                </div>
            }>
            <>
                {children}
                <footer className="popup-footer">
                    {deleteing ? <Button text={loading ? "טוען" : "מחק"} background="#2EAFE5" color="white" onClick={deleteing} disabled={loading} /> : null}
                    {keep ? <Button text={loading ? "טוען" : "שמור"} background="#2EAFE5" color="white" onClick={keep} disabled={loading} /> : null}
                </footer>
            </>
        </GenericPopUp>
    )
}

export default PopUp;