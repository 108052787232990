import { alertObj, messagesObj, textObj } from "../interfaces/CodeVerification.interface"

export const TEXT: textObj = {
    delete: 'הפעולה בוצעה בהצלחה',
    approve: 'הפעולה בוצעה בהצלחה',
    not_valid: 'הקישור לא תקף',
    error: 'ארעה שגיאה. נשלח אליך מייל חדש לביצוע הפעולה הנדרשת'
}


export const ALERTS: alertObj = {
    delete: 'success',
    approve: 'success',
    not_valid: 'error',
    error: "info"
}



export const MESSAGES: messagesObj = {
    delete: (code) => `הקוד ${code} נמחק בהצלחה. אנא כנסו לאתר על מנת לבדוק שאכן הפעולה בוצעה`,
    approve: (code) => `הקוד ${code} אושר בהצלחה. אנא עדכנו את העמותה שהם יכולים להתחיל להשתמש ברשיונות`,
    not_valid: () => `שגיאה זו ארעה כנראה עקב כך שהקישור כבר הופעל. בהצלחה ויום טוב!`,
    error: () => ''
}