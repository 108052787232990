
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Router as HistoryRouter } from "react-router-dom";
import { PrivateRoute, AuthProvider, PublicOnlyRoute } from "@hilma/auth";
import { provide } from "@hilma/tools";
import { AlertContext, AlertProvider } from "@yaeledri/use-alert";
import { CodeVerification } from './components/CodeVerification';
import MainApp from './pages/MainApp';
import Login from './pages/Login';
import { faChevronRight, faChevronLeft, faPlus, faSearch, faTimes, faArrowRight, faTrash, faPen, faCalendarDay, faEye, faEyeSlash, faCopy, faInfoCircle, faExclamationCircle, faClock, faCheckCircle, faSyncAlt} from '@fortawesome/free-solid-svg-icons'
import { UserProvider } from '@hilma/auth';
import { library } from '@fortawesome/fontawesome-svg-core';
import './styles/app.scss'
import { Restore } from './pages/Restore';
import { Forbidden } from './components/Forbidden';
import { history } from './interceptors/forbiddenInterceptor';
library.add(faChevronRight, faChevronLeft, faPlus, faSearch, faTimes, faArrowRight, faTrash, faPen, faCalendarDay, faEye, faEyeSlash, faCopy, faInfoCircle, faExclamationCircle, faClock, faCheckCircle, faSyncAlt)

function App() {
  const { createAlert } = useContext(AlertContext);

  function errorBringingUser() {
    createAlert('אירעה שגיאה בנסיון להביא את פרטי המשתמש')
  }

  return (
    <div className="App">
      <AlertProvider>
        <UserProvider url='/api/admin/get-admin' onError={errorBringingUser}>
          <Router>
            <HistoryRouter history={history} >
              <Switch>
                <Route exact path="/mails" component={CodeVerification} />
                <Route exact path='/forbidden-request' component={Forbidden} />
                <PublicOnlyRoute exact path='/restore-password' component={Restore} redirectComponent={MainApp} componentName='MainApp' />
                <PrivateRoute path='/' component={MainApp} redirectComponent={Login} componentName='MainApp' />
              </Switch>
            </HistoryRouter>
          </Router>
        </UserProvider>
      </AlertProvider>
    </div>
  );
}
export default provide(
  [AuthProvider, {
    logoutOnUnauthorized: true,
    accessTokenCookie: 'kloklo',
    twoFactorCookie: 'klokiloki'
  }],
)(App);
