import React from 'react'
import { CircularProgress } from '@material-ui/core';
import { FullLoadingProps } from '../interfaces/FullLoading.interface';
import '../styles/FullLoading.scss';

export const FullLoading: React.FC<FullLoadingProps> = ({ open }: FullLoadingProps) => {
    return (open ?
        <div className='full-loading'>
            <CircularProgress style={{ color: "#2a3474" }} />
        </div> : null
    )
}

