import React from 'react';
import { Button, FormField } from './FormAttributes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularProgress } from '@material-ui/core';
import { SettingFormProps } from '../interfaces/SettingForm.interface'
import '../styles/settingForm.scss'

export const SettingForm: React.FC<SettingFormProps> = ({ fields, values, onChange, save, disabled, title, showPsw, setShowPsw, saveArg, errors, onBlur, loading }: SettingFormProps) => {
    return (
        <div className="inputs-block">
            <div className="inputs-block--button">
                <span className="title">{title}</span>
                <Button onClick={() => save(saveArg)} color="" text="שמור" width={10} disabled={disabled} />
            </div>
            <hr className='break'/>
            {
                fields.map(field =>
                    <span key={field.name} className="inputs-block--label">
                        <FormField
                            id={field.name}
                            error={errors[field.name]}
                            fieldLabel={field.label}
                            value={values[field.name]}
                            israeliPhone={true}
                            onChange={onChange}
                            onBlur={onBlur}
                            type={field.name.includes("psw") && !showPsw?.[field.name] ? "password" : "text"}
                            icon={!field.name.toLowerCase().includes("psw") ? null :
                                <FontAwesomeIcon
                                    onClick={() => setShowPsw && setShowPsw(field.name, !showPsw?.[field.name])}
                                    name={field.name}
                                    id={field.name}
                                    icon={["fas", (showPsw?.[field.name] ? "eye" : "eye-slash")]}
                                />}
                        />
                    </span>
                )
            }
            {loading === saveArg ? <div className="inputs-block--loading">
                <CircularProgress />
            </div> : <></>}
        </div>
    )
}
