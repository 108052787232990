import { ChangeEvent } from "react";

export interface formObject {
    autoActive: boolean;
    orgName: string;
    contactName: string;
    phone: string;
    email: string;
    // password: string;
    expireDate: Date | null;
    numOfLicenses: number;
    activeLicenses: number;
    baseCode: string
}

export enum barEnum {
    details = "details",
    licenses = "licenses"
}

export type errorType = {
    [key in keyof formObject]?: string;
};

export type errorMassagesType = {
    [key: string]: string;
}

export type lengthType = Record<"min" | "max", [number, string]>

export type validationObject = {
    regexes: string[][],
    length?: lengthType
}

export type checkRegexType = Omit<Record<keyof formObject, validationObject>, "expireDate" | "activeLicenses" | "baseCode" | "autoActive">


export type regexType = Record<"lettersOnly" | "thereAreLetters" | "phoneRightCharacters" | "phoneHasNumbers" | "email" | "numbersOnly" /* | "password" */, typeof RegExp>

export type emailTypes = 'data' | 'approve';

type disabledMailButton = {
    [key in emailTypes]: boolean
}

interface sendMail {
    disabledMail: disabledMailButton,
    onEmailClick: (type: emailTypes) => any
}

export interface DetailsProps {
    formValues: formObject,
    errors: errorType,
    autoChange: (event: (ChangeEvent<HTMLInputElement> | { target: { name: string, checked: boolean } })) => void,
    onChange: (event: (ChangeEvent<HTMLInputElement> | { target: { value: any, name: string } })) => void,
    mode?: ADD_EDIT_ENUM,
    // hasPassword?: boolean,
    disabled?: boolean
    deleteError?: (field: string) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
    sendMail?: sendMail
}

export enum ADD_EDIT_ENUM {
    add = "ADD",
    edit = "EDIT"
}

export const MAX_LICENSES = 6000;

export const domainRegex = /^(https:\/\/)([w]{3}.)?[a-zA-Z0-9-.]{3,190}(.carmel6000)?.com\/api$/


export const REGEX = {
    lettersOnly: /^[א-ת a-z A-Z ’'."-]*$/,
    thereAreLetters: /^$|^(?=.*[א-תa-zA-Z])[א-תa-zA-Z ’'"-]*$/,
    phoneRightCharacters: /^$|^[0-9 +–-]*$/,
    phoneHasNumbers: /^$|^(?=.*[0-9])[0-9 +–-]*$/,
    email: /^[\w\.!#$%&'*+\-\/=?^_`{|}~]+@([\w-]+\.)+[\w-]{2,4}$/,
    password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]*$/,
    numbersOnly: /^[0-9]*$/,
    name: /^[א-תa-zA-Z '-]*$/,
    phone: /^[5798432][0-9]{8}$/
}

export const checkRegex: checkRegexType = {
    orgName: {
        regexes: [["lettersOnly", "orgNameLettersOnly"],
        ["thereAreLetters", "orgNameThereAreLetters"]],
        length: {
            min: [2, "orgNameMinLength"], max: [255, "orgNameMaxLength"]
        }
    },
    contactName: {
        regexes: [["lettersOnly", "contactNameLettersOnly"],
        ["thereAreLetters", "contactNameThereAreLetters"]],
        length: {
            min: [2, "contactNameMinLength"], max: [255, "contactNameMaxLength"]
        }
    },
    phone: {
        regexes: [["phoneRightCharacters", "phoneRightCharacters"],
        ["phoneHasNumbers", "phoneHasNumbers"]],
        length: {
            min: [9, "phoneMinLength"], max: [16, "phoneMaxLength"]
        }
    },
    email: {
        regexes: [["email", "email"]],
    },

    // password: {
    //     regexes: [["password", "password"]],
    //     length: {
    //         min: [8, "passwordMinLength"], max: [20, "passwordMaxLength"]
    //     }
    // },

    numOfLicenses: {
        regexes: [["numbersOnly", "numbersOnly"]],
    }
}

export const ERRORS: errorMassagesType = {
    empty: "הכנס/י ערך",

    orgNameLettersOnly: "שם העמותה יכול להכיל רק אותיות בעברית, באנגלית וסימנים מיוחדים מבין הבאים: ’'.\"-",
    orgNameThereAreLetters: "שם העמותה צריך להכיל לפחות אות אחת בעברית או באנגלית",
    orgNameMinLength: "שם עמותה צריך להיות באורך מינימאלי של 2 תווים",
    orgNameMaxLength: "שם עמותה לא יכול להכיל יותר מ 255 תווים",

    contactNameLettersOnly: "שם איש הקשר יכול להכיל רק אותיות בעבריתת באנגלית וסימנים מיוחדים מבין הבאים: ’'.\"-",
    contactNameThereAreLetters: "שם איש הקשר צריך להכיל לפחות אות אחת בעברית או באנגלית",
    contactNameMinLength: "שם איש קשר צריך להיות באורך מינימאלי של 2 תווים",
    contactNameMaxLength: "שם איש קשר לא יכול להכיל יותר מ 255 תווים",

    phoneRightCharacters: "מספר הטלפון יכול להכיל רק מספרים",
    phoneHasNumbers: "מספר הטלפון חייב להכיל מספרים",
    phoneMinLength: "טלפון צריך להיות באורך מינימלי של 10 תווים",
    phoneMaxLength: "טלפון יכול לכלול עד 16 תווים",
    phoneLength: "מספר פלאפון יכול להכיל רק 9 ספרות",
    phoneFormat: "פורמט מספר הפלאפון הרצוי הוא: 5xxxxxxxx",
    newPhoneNotInFormat: 'מספר פלאפון לא תואם את הפורמט הרצוי',
    
    email: "אימייל אינו בפורמט המתאים",
    wrongEmail: "כתובת המייל אינה תקינה",
    
    password: "הסיסמא חייבת לכלול לפחות אות אנגלית אחת, מספר אחד ותו מיוחד מהתווים הבאים: @$!%*#?&",
    passwordLength: 'סיסמא צריכה להיות בין שמונה לעשים תווים',
    passwordMinLength: "סיסמא חייבת לכלול לפחות 8 תווים",
    passwordMaxLength: "סיסמא חייבת לכלול עד 20 תווים ",
    newPasswordNotInFormat: "הסיסמא החדשה לא בפורמט המתאים",
    passwordAndVerifyNotTheSame: "הסיסמא החדשה ואימות הסיסמא לא תואמים",
    wrongPassword: "סיסמא לא נכונה",

    newUsernameNotInFormat: 'שם לא תואם את הפורמט הרצוי',

    nameOnlyLetters: "שם יכול להכיל רק אותיות באנגלית, אותיות בעברית, גרש ומקו",

    numbersOnly: "מספר הרישיונות יכול להכיל מספרים בלבד",

    cookieProblem: 'הפרטים שונו אך לא התעדכנו במידע השמור בדפדפן. אנא התחברו מחדש על מנת לעדכן מידע זה',
    generalUpdateError: 'ארעה שגיאה בעדכון הפרטים. אנא נסו שנית'
}