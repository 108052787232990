import React from 'react'
import { LoginExtrasProps } from '../interfaces/LoginExtras.interface'

export const LoginExtras: React.FC<LoginExtrasProps> = ({ dataObj, onClick }: LoginExtrasProps) => {
    return (
        <>
            {dataObj.map(({ id, text, linkText }) =>
                <p key={id} className='under-submit'>
                    {text}
                    <span className="link" id={id} onClick={onClick}>
                        {linkText}
                    </span>
                </p>
            )}
        </>
    )
}
