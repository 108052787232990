import { LoginType } from './../enums/LoginType.enum';
export interface projectsProps {
    history: {
        push: Function
    }
}

export interface ProjectData {
    id: number,
    name: string,
    domain: string,
    clients_num: string,
    login_type: LoginType
}

export enum OpenPopupType {
    UPDATE = 'update',
    INSERT = 'insert',
    DELETE = "delete"
}

export interface OpenPopup {
    value: boolean,
    type: OpenPopupType,
}

export interface InputArr {
    text: string,
    type: string,
    attribute: "name" | "domain" | "login_type"
}

export interface radioButton {
    id: LoginType
}

export const inputArr: InputArr[] = [
    {
        text: "שם הפרויקט",
        type: "text",
        attribute: "name"
    },
    {
        text: "דומיין",
        type: "text",
        attribute: "domain"
    },
    {
        text: "סוג הרשמה",
        type: "radio",
        attribute: "login_type"
    }
];

export const radioButtons: radioButton[] = [
    {
        id: LoginType.GOOGLE,
        // name: "גוגל",
    },
    {
        id: LoginType.REGULAR,
        // name: "הרשמה רגילה",
    }
]