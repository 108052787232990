import { Alert } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router';
import { TEXT, ALERTS, MESSAGES } from "../consts/codeVerification";
import { paramsTypes } from "../interfaces/CodeVerification.interface";
import { NotFound } from './NotFound'
import { TopBar } from "./TopBar";
import queryString from 'query-string';
import '../styles/codeVerification.scss';


export const CodeVerification: React.FC = () => {
    const [params, setParams] = useState<paramsTypes>({ type: '', code: '' });
    const location = useLocation()

    useEffect(() => {
        const { type, code } = queryString.parse(location.search);
        setParams(prev => ({ ...prev, type, code }));
    }, [location]);


    return <div className='container'>
        <TopBar />

        <div className='code-verification'>
            {TEXT[params.type] ?
                <div className='message'>
                    <Alert severity={ALERTS[params.type] || 'info'}>{TEXT[params.type] || ''}</Alert>
                    <p className='details'>
                        {MESSAGES[params.type](params.code)}
                    </p>
                </div>
                :
                <NotFound />
            }
        </div>

    </div>
}
