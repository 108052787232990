import React, { useEffect, useRef, useState } from 'react';
import { genericTableProps } from '../interfaces/GenericTable.interface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/table.scss'

/** props:
  *    th- array of arrays with the name of the headline in english and the language of the page. like: [['name', 'שם'], ['phone', 'פלאפון']]
  *    tr- array of arrays with the html elements of the row in the order of the th. like: [[<div>ראשית</div>, <div>0556683720</div>], [<div>מעיין</div>, <div>0556653379</div>]]
  *    loading- boolean, true if the table loads.
  *    navigation- boolean, arrows back and forward.
          if navigation is true:
              prevPage- function, onClick the right arrow.
              nextPage- function, onClick the left arrow.
              rowsNum- the number of rows for each page
              resultsNum- the number of all the columns in the database. like: יש לי 345 אנשים שמחפשים בעל תוקע ואני מראה רק 20 מתוכם. המספר של הפרופס הוא 345
        onRowClick- onClick event to happen on click of single row, passes the event and the index 
        icon - true if the last column if an icon and false otherwise - for the style of it
*/

const GenericTable: React.FC<genericTableProps> = React.memo(({
    prevPage, page, nextPage, resultsNum, rowsNum, showMore, showMoreOpen, ourKey, 
    th, tr, secondTr, loading, navigation, rowStart, icon, onRowClick, changeShow
}: genericTableProps) => {
    const [lessHeight, setLessHeight] = useState<number>(0)
    const [moreHeight, setMoreHeight] = useState<number>(0)
    const [didMount, setDidMount] = useState<boolean>(true)
    const tableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (showMore && tableRef.current) tableRef.current.style.height = showMoreOpen ? (lessHeight + moreHeight) + 'px' : lessHeight + 'px'
    }, [moreHeight, lessHeight, showMoreOpen, showMore])

    useEffect(() => {
        if (!showMore) return
        const less: any = document.getElementsByClassName('showLess' + ourKey)
        const more: any = document.getElementsByClassName('showMore' + ourKey)
        const showMoreRow = document.getElementsByClassName('tableShowMore')
        const tableHeadRow = document.getElementsByClassName('tableHead')
        const showMoreRowHeight = showMore ? showMoreRow[0].getBoundingClientRect().height : 0
        const tableHeadRowHeight = tableHeadRow[0].getBoundingClientRect().height

        if (less) {
            let sumLess = 0;
            for (let item of less) {
                sumLess += item.getBoundingClientRect().height
            }
            setLessHeight(sumLess + showMoreRowHeight + tableHeadRowHeight)
        }
        if (more) {
            let sumMore = 0;
            for (let item of more) {
                sumMore += item.getBoundingClientRect().height
            }
            setMoreHeight(sumMore)
        }
        if (didMount) {
            setDidMount(false)
            return
        }
        const title = document.getElementById('title-' + ourKey)
        if (title) title.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        //eslint-disable-next-line
    }, [showMoreOpen, ourKey, showMore])

    const prevPageClicked = () => {
        if (typeof prevPage !== 'function') return
        if (1 === page) return
        prevPage(page - 1)
    }

    const nextPageClicked = () => {
        if (typeof nextPage !== 'function' || !resultsNum || !rowsNum) return
        if (Math.ceil(resultsNum / rowsNum) === page) return
        nextPage(page + 1)
    }
    
    return (
        <div
            style={showMore ? {
                height: showMoreOpen ? (lessHeight + moreHeight) + 'px' : lessHeight + 'px',
                overflow: 'hidden',
                transition: `height 0.4s linear`
            } : {}}
            ref={tableRef}
            className="table-and-navigation"
        >
            <table className="allTableStyle">
                <thead>
                    {th ?
                        <tr className="tableHead">
                            {th.map((item: any, index: number) => item ? <th key={index} className={item[0]}>{item[1]}</th> : null)}
                        </tr> :
                        null
                    }
                </thead>
                <tbody>
                    {!tr && !secondTr ?
                        loading ?
                            <tr className='headLine'>
                                <td colSpan={9} className='noResults'>
                                    <CircularProgress style={{ color: "#2A3474" }} />
                                </td>
                            </tr> :
                            <tr className='headLine' style={{ cursor: 'unset' }}>
                                <td colSpan={9} className='noResults'>אירעה שגיאה, נסו שנית מאוחר יותר</td>
                            </tr> :
                        (tr && !tr.length && !showMore ?
                            <tr className='headLine' style={{ cursor: 'unset' }}>
                                <td colSpan={9} className='noResults'>לא נמצאו תוצאות</td>
                            </tr> :
                            loading ?
                                <tr className='headLine' style={{ cursor: 'unset' }}>
                                    <td colSpan={9} className='noResults'>
                                        <CircularProgress style={{ color: "#2A3474" }} />
                                    </td>
                                </tr> :
                                <>
                                    {tr && tr.map((td: any, i: number) =>
                                        i >= rowStart && rowsNum && i < rowsNum * (page) ?
                                            <tr key={i} className={"tableBodyStyle showLess" + ourKey} onClick={e => { if (onRowClick) onRowClick(e, i, td) }} >
                                                {td.map((j: any, index: number) =>
                                                    <td key={index} className={(th ? th[index] && th[index][0] : '') + (icon && td.length - 1 === index ? " icon" : "")} > {j}</td>
                                                )}
                                            </tr> :
                                            <React.Fragment key={i}></React.Fragment>
                                    )}
                                    {secondTr && showMoreOpen && secondTr.map((td: any, i: number) =>
                                        i >= rowStart && rowsNum && i < rowsNum * (page) ?
                                            <tr key={i} className={"tableBodyStyle showMore" + ourKey} onClick={e => { if (onRowClick) onRowClick(e, i, td) }} >
                                                {td.map((j: any, index: number) =>
                                                    <td key={index} className={th ? th[index] && th[index][0] : ''} > {j}</td>
                                                )}
                                            </tr> :
                                            <React.Fragment key={i}></React.Fragment>
                                    )}
                                </>
                        )
                    }
                </tbody>
            </table>
            {
                showMore &&
                <div className='tableShowMore' style={{ cursor: 'unset' }}>
                    <div className="show-more" onClick={() => typeof changeShow === 'function' && changeShow()}>{showMore}</div>
                </div>
            }
            {
                navigation &&
                <div className='tableNavigation'>
                    <FontAwesomeIcon icon={["fas", 'chevron-right']} className={'navArrow' + (page === 1 ? ' disabledNavArrow' : '')} onClick={prevPageClicked} />
                    <div>עמוד {page} מתוך {resultsNum && rowsNum ? (Math.ceil(resultsNum / rowsNum) === 0 ? 1 : Math.ceil(resultsNum / rowsNum)) : 1}</div>
                    <FontAwesomeIcon icon={["fas", "chevron-left"]} className={'navArrow' + ((resultsNum && rowsNum ? (Math.ceil(resultsNum / rowsNum) === 0 ? 1 : Math.ceil(resultsNum / rowsNum)) : 1) === page ? ' disabledNavArrow' : '')} onClick={nextPageClicked} />
                </div>
            }
        </div >
    );
})

export default GenericTable;