export const TH = {
    projects: [
        ["project-name", 'שם הפרויקט'],
        ['number-of-organization', 'מספר עמותות'],
        ['login-type', 'דרך הרשמה'],
        ["domain", 'דומיין'],
        ["icon", ""],
        ["icon", ""]
    ],
    organization: [
        ["org-name", 'שם העמותה'],
        ["total-licenses", 'רישיונות'],
        ["expire_date", 'תוקף רישיון'],
        ["license_type", "סוג רישיון"]
    ],
    purchases: [
        ["status", "סטטוס"],
        ["license-name", 'שם העמותה'],
        ["username", 'אימייל'],
        ["project_name", 'פרויקט'],
        ["date", 'תאריך'],
        ["license_type", 'סוג רישיון'],
        ['more-info', 'מידע נוסף']
    ]
}