import { RefObject, useEffect } from 'react';

interface ValidRefTarget {
    contains(target: EventTarget | null): any;
}

function useOnClickOutside(ref: RefObject<ValidRefTarget>, handler: (e: Event) => void, exceptRef?: RefObject<ValidRefTarget>) {
    useEffect(() => {
        const listener = (event: Event) => {
            if ((!ref.current || ref.current.contains(event.target) || (exceptRef?.current && exceptRef.current.contains(event.target)))) {
                return;
            }
            handler(event);
        };

        document.addEventListener("mousedown", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
        };
        //eslint-disable-next-line
    }, [ref, exceptRef, handler]);
}

export default useOnClickOutside;