import { CODE_EXTRA, LOGIN_EXTRA, RESTORING_EXTRA, LOGIN_ERRORS, EMPTY_LOGIN_FORM, EMPTY_CODE_FORM, EMPTY_LOGIN_RESTORE_FORM, EMPTY_KEPT_FORM } from '../consts/loginConsts';
import { useHasTwoFactorToken, useSendCode, useLoginWithCode } from '@hilma/auth';
import { formStates, keptForm, loginExtraData, loginForm } from '../interfaces/Login.interface';
import React, { useState, useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { AlertContext } from '@yaeledri/use-alert'
import { LoginExtras } from '../components/LoginExtras';
import { FullLoading } from '../components/FullLoading';
import { LoginForm } from '../components/LoginForm';
import { TopBar } from '../components/TopBar';
import { REGEX } from '../consts/validation';
import axios from 'axios';
import '../styles/login.scss'


const Login: React.FC<RouteComponentProps> = () => {
    const [form, setForm] = useState<loginForm>(EMPTY_LOGIN_FORM);
    const [keptForm, setKeptForm] = useState<keptForm>(EMPTY_KEPT_FORM);
    const [formExtra, setFormExtra] = useState<loginExtraData[]>(LOGIN_EXTRA)
    const [formState, setFormState] = useState<formStates>("user-psw");
    const [loading, setLoading] = useState<boolean>(false);

    const { createAlert } = useContext(AlertContext);

    const hasTwoFactorToken = useHasTwoFactorToken();
    const sendCode = useSendCode();
    const loginWithCode = useLoginWithCode();

    // function to change the form's state in mounting if the user have a code credentials
    useEffect(() => {
        changeForm(hasTwoFactorToken ? 'code' : 'user-psw');
        // eslint-disable-next-line
    }, [])

    // function to change the form by the form's state
    useEffect(() => {
        returnValuesToForm(formState);
        switch (formState) {
            case 'code':
                setFormExtra(CODE_EXTRA);
                break;
            case 'restore':
                setFormExtra(RESTORING_EXTRA);
                break;
            case 'user-psw':
                setFormExtra(LOGIN_EXTRA);
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [formState])

    // generic function for validation to user-psw
    const validate = () => {
        let valid = true
        Object.keys(form).map(field => {
            let err = "";
            const value = form[field].value;
            if (field === 'email' && !(REGEX.email).test(value)) {
                if (value === "") err = LOGIN_ERRORS.noUsername;
                else err = LOGIN_ERRORS.invalidUsername;
            }
            if (field === 'psw') {
                if (value === "") err = LOGIN_ERRORS.noPsw;
                else if (value.length < 6) err = LOGIN_ERRORS.shortPsw;
                else if (value.length > 20) err = LOGIN_ERRORS.longPsw;
            }
            if (field === 'code') {
                if (value === "") err = LOGIN_ERRORS.noCode;
                else if (!(REGEX.numbersOnly).test(value)) err = LOGIN_ERRORS.invalidCode;
            }
            if (err) {
                createAlert(err);
                valid = false;
            }
            return err;
        })
        return valid;
    }

    // function for handling the errors in the server requests
    const errorHandler = (msg: any) => {
        switch (msg?.status || msg?.statusCode) {
            case 500:
                createAlert(LOGIN_ERRORS.tryLater);
                break;
            case 401:
                if (msg?.data?.key === "PassDoesntMatch") createAlert(LOGIN_ERRORS.wrongPsw);
                else if (msg?.data?.key === "NoUsername") createAlert(LOGIN_ERRORS.wrongUsername);
                else if (msg?.data?.key === "UserIsBlocked") createAlert(LOGIN_ERRORS.userBlocked);
                else if (msg?.data.key === "IncorrectCode") createAlert(LOGIN_ERRORS.incorrectCode);
                else if (msg?.data?.key === "MaxAttempts") createAlert(LOGIN_ERRORS.maxAttempts);
                else if (msg?.data.key === "CodeHasExpired") createAlert(LOGIN_ERRORS.codeHasExpired);
                else if (msg?.statusText === "Unauthorized") createAlert(LOGIN_ERRORS.unauthorized)
                else createAlert(LOGIN_ERRORS.tryLater)
                break;
            case 400:
                if (msg?.data?.error === "NoPhone") createAlert(LOGIN_ERRORS.noPhone);
                else createAlert(LOGIN_ERRORS.tryLater);
                break;
            default:
                createAlert(LOGIN_ERRORS.tryLater);
                break;
        }
    }

    // function for submitting the user-psw form
    const handleSubmitUserPsw = async (e: React.MouseEvent<HTMLButtonElement | HTMLSpanElement, MouseEvent>) => {
        e.preventDefault();
        if (!validate()) return;
        setLoading(true);
        const { success, msg } = await sendCode('/api/two-factor/send-code', {
            username: form.email?.value,
            password: form.psw?.value
        });
        setLoading(false)
        if (success) {
            setFormState('code');
            return;
        };
        errorHandler(msg);
    }

    // function for for submitting code form
    const handleSubmitCode = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (!validate()) return;
        setLoading(true);
        const { success, msg } = await loginWithCode('/api/two-factor/login-with-code', form.code?.value || "");
        if (success) return;
        setLoading(false);
        errorHandler(msg);
    }

    // function for submitting restore form
    const handleSubmitRestore = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        if (!validate()) return;
        try {
            const body = {
                username: form.email?.value || ""
            }
            await axios.post('/api/admin/send-restore-mail', body);
            changeForm('user-psw');
            createAlert('המייל נשלח בהצלחה', 'success');
        } catch (err) {
            errorHandler(err);
            console.log(err);
        }

        setLoading(false);
    }

    // function for changing the form's values states
    const changeForm = (state: formStates) => {
        Object.keys(form).map(field => {
            setKeptForm(prev => { return { ...prev, [field]: form[field].value } });
            return field;
        })
        setFormState(state);
    }

    // generic function for clicking the links in the bottom of the forms
    const handleGenericClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.preventDefault();
        const { id } = e.target as Element;
        if (id === 'new-code') {
            if (!keptForm.email || !keptForm.psw) {
                changeForm('user-psw');
                createAlert('אנא הכנס פרטי משתמש שנית', 'warning');
                return;
            }
            handleSubmitUserPsw(e);
            return;
        }

        if (id === 'code' || id === 'user-psw' || id === 'restore') {
            changeForm(id);
        }
    }

    // generic function for clicking the button in the forms
    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const { id } = e.target as Element;
        if (id === 'code') handleSubmitCode(e);
        else if (id === 'user-psw') handleSubmitUserPsw(e);
        else if (id === 'restore') handleSubmitRestore(e);
    }

    // function that saves the values of the form for later when changing form state
    const returnValuesToForm = (toStatus: formStates) => {
        let emptyForm: loginForm = {};
        const { code, email, psw } = keptForm;
        if (toStatus === 'code') {
            emptyForm = EMPTY_CODE_FORM;
            emptyForm.code && (emptyForm.code.value = code);
        };
        if (toStatus === 'user-psw') {
            emptyForm = EMPTY_LOGIN_FORM;
            emptyForm.email && (emptyForm.email.value = email);
            emptyForm.psw && (emptyForm.psw.value = psw);
        };
        if (toStatus === 'restore') {
            emptyForm = EMPTY_LOGIN_RESTORE_FORM;
            emptyForm.email && (emptyForm.email.value = email);
        };
        setForm(emptyForm);
    }

    return (
        <div className='login-component' >

            <TopBar isLoginPage />

            <div className='form'>

                <span className="title">רשיונות הילמה</span>

                <LoginForm inputsObj={form} setForm={setForm} />

                <button className='submit-input' id={formState} onClick={handleButtonClick} disabled={loading} >
                    {formState === 'code' ? "התחבר" : formState === 'user-psw' ? 'שלח קוד הזדהות' : 'שלח מייל לשחזור סיסמה'}
                </button>

                <LoginExtras dataObj={formExtra} onClick={handleGenericClick} />

                <FullLoading open={loading} />

            </div>

        </div>
    )
}
export default Login;