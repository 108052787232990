import { BrowserRouter as Router, Switch, RouteComponentProps, Route } from 'react-router-dom';
import AddEditOrganization from './AddEditOrganization';
import { PrivateRoute } from '@hilma/auth';
import Organizations from "./Organizations"
import { Purchases } from './Purchases';
import { NotFound } from '../components/NotFound';
import { Restore } from './Restore';
import { TopBar } from '../components/TopBar';
import Settings from "./Settings";
import Projects from "./Projects";
import React from 'react'
import Tabs from '../components/Tabs';

const MainApp: React.FC<RouteComponentProps> = () => {
    return (
        <div className="main-app">
            <TopBar
                imgPath={""}
            />
            <Router>
                <div className="main-content">
                    <div className="main-content-inside">
                        <Tabs />
                        <Switch>
                            <PrivateRoute exact path='/' component={Projects} componentName="Projects" />
                            <PrivateRoute exact path='/projects' component={Projects} componentName="Projects" />
                            <PrivateRoute exact path='/purchases' component={Purchases} componentName="Purchases"/>
                            <PrivateRoute exact path='/projects/organizations' component={Organizations} componentName="Organizations" />
                            <PrivateRoute exact path='/projects/organizations/add-edit' component={AddEditOrganization} componentName='AddEditOrganization' />
                            <PrivateRoute exact path='/settings' component={Settings} componentName='Settings' />
                            <PrivateRoute exact path='/restore-password' component={Projects} redirectComponent={Restore} componentName='Projects' />
                            <Route path="*" component={NotFound} />
                        </Switch>
                    </div>
                </div>
            </Router>
        </div>
    )
}

export default MainApp;
