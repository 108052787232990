import React from 'react'
import { RestoringPswFormProps } from '../interfaces/RestoringPswForm.interface'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputFormater from './InputFormater'

export const LoginForm: React.FC<RestoringPswFormProps> = ({ inputsObj, setForm }: RestoringPswFormProps) => {
    return (
        <>
            {Object.keys(inputsObj).map(field =>
                <div key={field} className='password'>
                    <InputFormater
                        inputName={inputsObj[field].name}
                        inputType={inputsObj[field].show ? "text" : inputsObj[field].type}
                        handleChange={e => setForm(prev => ({ ...prev, [field]: { ...prev[field], value: e.target.value } }))}
                        stateValue={inputsObj[field].value}
                        placeholder={inputsObj[field].placeHolder} />

                    {inputsObj[field].icon ?
                        <FontAwesomeIcon
                            icon={inputsObj[field].show ? faEye : faEyeSlash}
                            className='password-icon'
                            color={'#4D4C4C'}
                            onClick={() => setForm(prev => ({ ...prev, [field]: { ...prev[field], show: !prev[field].show } }))} /> : null}
                </div>
            )}
        </>
    )
}
