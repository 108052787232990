import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchBarProps } from '../interfaces/SearchBar.interface'
import { Input } from './FormAttributes';
import "../styles/searchBar.scss";

const SearchBar: React.FC<SearchBarProps> = ({ search, value, setInput, filter }: SearchBarProps) => {
    const [delayTime, setDelayTime] = useState<ReturnType<typeof setTimeout> | null>();

    useEffect(() => {
        if (delayTime) clearTimeout(delayTime)
        if (typeof search === 'function') {
            if (value === '') search(value)
            else setDelayTime(setTimeout(() => search(value), 300))
        }
        // eslint-disable-next-line
    }, [value])

    function onChange(e: React.FormEvent<HTMLInputElement>) {
        setInput(e.currentTarget.value);
    }

    function deleteValue() {
        setInput("");
    }

    return (
        <div className="search-bar">
            <div className="search-bar--input-container">
                <Input
                    placeHolder={"חיפוש"}
                    type={"text"}
                    value={value}
                    onChange={onChange} />
                <div className="search-bar--icons">
                    {
                        (!value || value.length === 0) ?
                            <FontAwesomeIcon icon={['fas', 'search']} className={'input-icon'} /> :
                            <FontAwesomeIcon icon={['fas', 'times']} className={'input-icon times'} onClick={() => deleteValue()} />
                    }
                </div>

            </div>

            {filter}
        </div>
    )
}
export default SearchBar;
