import React from 'react';
import { CircularProgress, Dialog } from "@material-ui/core";
import { GenericPopUpProps } from '../interfaces/GenericPopUp.interface'
import { GenericIcon } from './FormAttributes';
import '../styles/popUp.scss';

export const GenericPopUp: React.FC<GenericPopUpProps> = ({ open, close, leftOfTop, middleOfTop, children, loading, width, height, bottomWidth, topHeight, ...otherProps }: GenericPopUpProps) => {
    return (
        <Dialog
            {...otherProps}
            maxWidth="xs"
            open={open}
            onClose={close}
            aria-labelledby="simple-dialog-title"
            className="popup-container noselect">
            {loading ?
                <div className="popup--loading">
                    <CircularProgress style={{ color: "#2A3474", margin: "0 auto" }} />
                </div>
                : null}
            <div
                className={`popup${loading ? "-with-loading" : ""}`}
                style={{ height: height, width: width }}>
                <div className='popup--top' style={{ height: topHeight }}>
                    <span className='popup--top--right'>
                        <GenericIcon icon="times" color="#a3a6b4" onClick={() => close()} />
                    </span>
                    <span className='popup--top--middle'>
                        {middleOfTop}
                    </span>
                    <span className='popup--top--left'>
                        {leftOfTop}
                    </span>
                </div>
                <div className="popup--scroll">
                    <div className="popup--bottom" style={{ width: bottomWidth }}>
                        {children}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
