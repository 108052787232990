export interface Inputs {
    username: string,
    psw :string,
    newpsw: string,
    verifyPsw: string,
    phone: string,
    name: string
}

export interface Psw {
    psw: boolean,
    newpsw: boolean,
    verifyPsw: boolean
}

export interface InputFields {
    name: keyof Psw | typesOfSetting,
    label: string
}

export type typesOfSetting = "psw" | "username" | "phone" | "name";

export interface SettingForm {
    fields: InputFields[],
    title: string,
    saveArg: typesOfSetting
}

export const usernameInput: InputFields[] = [
    {
        name: 'username',
        label: 'שם משתמש'
    }
]

export const nameInput: InputFields[] = [
    {
        name: 'name',
        label: 'שם'
    }
]

export const phoneInput: InputFields[] = [
    {
        name: 'phone',
        label: 'מספר פלאפון'
    }
]

export const pswInputs: InputFields[] = [
    {
        name: 'psw',
        label: 'סיסמא'
    }, {
        name: 'newpsw',
        label: 'סיסמא חדשה'
    }, {
        name: 'verifyPsw',
        label: 'אימות סיסמא'
    }
    
]

export const SettingForms: SettingForm[] = [
    {
        fields: usernameInput,
        title: "שינוי שם משתמש",
        saveArg: "username"
    },
    {
        fields: nameInput,
        title: "שינוי שם",
        saveArg: "name"
    },
    {
        fields: phoneInput,
        title: "שינוי מספר פלאפון",
        saveArg: "phone"
    },
    {
        fields: pswInputs,
        title: "שינוי סיסמא",
        saveArg: "psw"
    }
]

export const emptyForm = {
    username: "",
    psw: "",
    newpsw: "",
    verifyPsw: "",
    phone: "",
    name: ""
}

export const emptyPassword = {
    psw: false,
    newpsw: false,
    verifyPsw: false
}