import { DetailsProps, errorType, formObject } from '../consts/validation'
import { FormField } from './FormAttributes'
import OurDayPicker from './DayPicker'
import { fields } from '../consts/addEditOrganizationLicenses'
import React from 'react'

const AddEditOrganizationLicenses: React.FC<DetailsProps> = (props) => {
    return (
        <div className="AddEditOrgForm AddEditOrgLicenses">
            {fields.map(field =>
                <FormField
                    key={field.id}
                    id={field.id}
                    value={"" + props.formValues[field.id as keyof formObject]}
                    onChange={props.onChange}
                    disabled={field.id === "baseCode" || field.id === "activeLicenses" || props.disabled}
                    error={props.errors[field.id as keyof errorType] || ""}
                    onBlur={props.onBlur}
                    altInput={field.id === "expireDate" ?
                        <OurDayPicker
                            disabled={props.disabled}
                            date={props.formValues.expireDate}
                            setDate={(value: Date | null) => props.onChange({ target: { value, name: "expireDate" } })} />
                        : null}
                    fieldLabel={field.fieldLabel} />
            )}
        </div>
    )
}

export default AddEditOrganizationLicenses