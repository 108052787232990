import React from 'react'
import { NotFound } from './NotFound'
import { TopBar } from './TopBar';
import forbidden from '../media/forbidden.png';
import '../styles/codeVerification.scss';

export const Forbidden: React.FC = () => {
    return (
        <div className='container'>
            <TopBar />
            <div className="forbidden">
                <NotFound
                    img={forbidden}
                    text={"לא ניתן לגשת לאתר מחוץ למשרדי החברה"}
                    canGoBack={false} />
            </div>
        </div>
    )
}
