import React, { useContext, useEffect, useState } from 'react';
import { LicenseNames, organizationInterface, organizationProps, organizationsResInterface } from '../interfaces/Organization.interface'
import { BackArrow, AddIcon } from "../components/FormAttributes";
import { AlertContext } from "@yaeledri/use-alert"
import { ROWS_NUM } from '../consts/rowsNum';
import { TH } from '../consts/tableHeaders';
import GenericTable from '../components/GenericTable';
import SearchBar from "../components/SearchBar";
import Axios from 'axios';
import { LicenseNameTranslated, LicenseTypeChip } from '../components/LicenseTypeChip';
import { SelectForFilter, SetStateFilter } from '../components/SelectForFilter';

const Organizations: React.FC<organizationProps> = (props: organizationProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tablePage, setTablePage] = useState<number>(1);
    const [organizationArrayLength, setOrganizationArrayLength] = useState<number>(0);
    const [data, setData] = useState<organizationInterface[] | null>(null);
    const [search, setSearch] = useState<string>("");
    const [licenseType, setLicenseType] = useState<LicenseNames | "all">("all")

    const { createAlert } = useContext(AlertContext);

    // fetch organizations
    useEffect(() => {
        setTablePage(1);
        fetchOrganizations();
        //eslint-disable-next-line
    }, [search, licenseType])


    const fetchOrganizations = async (from: string = "") => {
        setLoading(true)
        try {
            // if from === "setPage": from the length of the existing data
            //    from === "": from the start, 0
            const fromParam = data && from === "changingPage" ? data.length : 0
            const { data: resData } = await Axios.post<organizationsResInterface>(`/api/license/get-licenses/`, {
                projectId: props.location.state.projectId,
                from: fromParam,
                search,
                licenseType: licenseType !== "all" ? licenseType : null
            })
            setData((prev: organizationInterface[] | null) =>
                // if changed page, save the prev data. otherwise, save the response
                prev !== null && from === "changingPage" ?
                    [...prev, ...resData.licenses] :
                    resData.licenses
            )
            setOrganizationArrayLength(resData.licensesLength)
        } catch (err) {
            createAlert("אירעה שגיאה בשרת, נסו שוב מאוחר יותר")
        }
        setLoading(false)
    }

    // change the page of the table
    const setPage = (page: number) => {
        if (data && data.length) {
            tablePage === Math.ceil(data.length / ROWS_NUM) && organizationArrayLength > data.length && fetchOrganizations('changingPage')
            setTablePage(page)
        }
    }

    // get back to projects' page
    const goBack = () => {
        props.history.goBack()
    }

    const onRowClick = (e: React.FormEvent<HTMLInputElement>, i: number, td: JSX.Element) => {
        props.history.push({ pathname: '/projects/organizations/add-edit', state: { organizationId: data![i].id, organizationName: data![i].full_name, projectName: props.location.state.projectName, projectId: props.location.state.projectId } });
    }

    const onSearch = () => {
        setTablePage(1)
        fetchOrganizations()
    }

    const addOrganization = () => {
        props.history.push({ pathname: '/projects/organizations/add-edit', state: { projectName: props.location.state.projectName, projectId: props.location.state.projectId } })
    }

    return (
        <div className="page organizations">
            <div className="top-page">
                <div className="header">
                    <BackArrow goBack={goBack} />
                    <p className="header--content">{props.location.state ? props.location.state.projectName : ""}</p>
                </div>
                <AddIcon onClick={addOrganization} />
            </div>

            <div className="filter-bar" style={{ display: 'flex' }}>

                <SearchBar
                    value={search}
                    setInput={setSearch}
                    search={onSearch}
                    filter={
                        <SelectForFilter
                            values={[["all", "כל הרישיונות"], ...Object.entries(LicenseNameTranslated)]}
                            value={licenseType}
                            setValue={setLicenseType as SetStateFilter} />
                    }
                />
            </div>

            <div className="table">
                <GenericTable
                    th={TH.organization}
                    tr={
                        data?.map((tr: organizationInterface) =>
                            [
                                <div>{tr.full_name}</div>,
                                <div>{tr.given_number_of_licenses}</div>,
                                <div>{tr.expire_date}</div>,
                                <LicenseTypeChip type={tr.license_type} />
                            ]
                        ) || null
                    }
                    loading={loading}
                    navigation={true}
                    onRowClick={onRowClick}
                    nextPage={setPage}
                    prevPage={setPage}
                    rowStart={tablePage === 1 ? 0 : (tablePage - 1) * ROWS_NUM}
                    rowsNum={ROWS_NUM}
                    page={tablePage}
                    resultsNum={organizationArrayLength} />
            </div>
        </div>
    )
}

export default Organizations;