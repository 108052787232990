import { MenuItem } from '@material-ui/core'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import { CustomizedInput, CustomizedSelect } from './CustomizedSelect'

export type SetStateFilter = Dispatch<SetStateAction<string>>;

interface SelectForFilterProps {
    value: string,
    values: Array<Array<string>>
    setValue: SetStateFilter
}

export const SelectForFilter: FC<SelectForFilterProps> = ({ value, setValue, values }) => {
    const changeValue = (e: ChangeEvent<{ value: string }>) => {
        setValue(e.target.value)
    }


    return (
        <CustomizedSelect
            value={value}
            input={<CustomizedInput />}
            onChange={changeValue as SelectInputProps['onChange']}>
            {
                values?.map(([key, type]) => (
                    <MenuItem key={key} value={key}>{type}</MenuItem>
                ))}

        </CustomizedSelect>
    )
}
