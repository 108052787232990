import { AddEditButtons } from './../interfaces/AddEditOrganization.interface';
import { ADD_EDIT_ENUM } from './validation';

export const fields = [
    { id: "orgName", fieldLabel: "שם העמותה" },
    { id: "contactName", fieldLabel: "איש קשר" },
    { id: "phone", fieldLabel: "טלפון" },
    { id: "email", fieldLabel: "דואר אלקטרוני" },
    // { id: "password", fieldLabel: "סיסמא" },
    { id: "autoActive", fieldLabel: "רישום אוטומטי בתור רישיון פעיל" },
]

export const { add, edit } = ADD_EDIT_ENUM;

export const ADD_EDIT_BUTTONS: AddEditButtons[] = [
    {
        text: "שליחת מייל לאישור יצירת רשיון",
        type: 'approve'
    }, {
        text: "שליחת פרטי מנוי במייל",
        type: 'data'
    }
]