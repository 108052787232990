import { Chip, makeStyles } from '@material-ui/core'
import React from 'react'
import { LicenseNames } from '../interfaces/Organization.interface'

interface LicenseTypeProps {
    type: LicenseNames
}

export type LicenseNameTranslatedType = Record<LicenseNames, string>;

export const LicenseNameTranslated: LicenseNameTranslatedType = {
    private: "פרטי",
    institutional: "ארגוני",
    customized: "מוסדי",
}

export const LicenseTypeChip: React.FC<LicenseTypeProps> = ({ type }) => {
    const classes = useStyles({ type })

    return (
        <Chip className={classes.root} label={LicenseNameTranslated[type]} />
    )
}


const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: (props: LicenseTypeProps) => props.type === LicenseNames.PRIVATE ? "#6CC06866" : (props.type === LicenseNames.INSTITUTIONAL ? "#FCB24D66" : "#E1663C66"),
        minWidth: '50%',
        color: "#737479",
        fontWeight: "bold",
        fontSize: "1.5vh",
        height: "3.5vh"
    }
}))
