import React, { useContext, useEffect, useState } from 'react'
import GenericTable from '../components/GenericTable'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { ROWS_NUM } from '../consts/rowsNum';
import { TH } from '../consts/tableHeaders'
import { LicenseNames } from '../interfaces/Organization.interface';
import { LicenseNameTranslated, LicenseTypeChip } from '../components/LicenseTypeChip';
import SearchBar from '../components/SearchBar';
import { SelectForFilter, SetStateFilter } from '../components/SelectForFilter';
import { GenericPopUp } from '../components/GenericPopUp'
import { Info } from '@material-ui/icons';
import { Button } from '../components/FormAttributes'
import { AlertContext } from '@yaeledri/use-alert';

export enum Statuses {
    FAILURE = "failure",
    SUCCESS = "success",
    WAITING = "waiting",
    REFUND = "refund"
}

export const StatusesTranslated: Record<Statuses, string> = {
    failure: "נכשל",
    success: "נרכש בהצלחה",
    waiting: "בהמתנה",
    refund: "החזר כספי"
}

interface Purchase {
    id: number,
    status: Statuses,
    paid: number,
    credit_card: string,
    date: string,
    username: string,
    name: string,
    license_type: LicenseNames,
    project_name: string
}

interface MoreInfo {
    id: number,
    paid: number,
    asmachta: string,
    card_suffix: string,
    license: {
        id: number,
        expire_date: string,
        full_name: string,
        base_code: string,
        license_type: string,
        given_number_of_licenses: number,
    }
}

const popupBody = [
    { val: "license.full_name", name: "שם העמותה:" },
    { val: "license.given_number_of_licenses", name: "מספר הרשיונות:" },
    { val: "license.expire_date", name: "תאריך תפוגה:" },
    { val: "card_suffix", name: "כרטיס אשראי:" },
    { val: "asmachta", name: "אסמכתא:" },
    { val: "paid", name: "שולם:" },
    { val: "license.base_code", name: "קוד:" },
]

export const Purchases = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [tablePage, setTablePage] = useState<number>(1);
    const [totalLength, setTotalLength] = useState<number>(0);
    const [purchases, setPurchases] = useState<Purchase[] | null>(null);
    const [licenseType, setLicenseType] = useState<LicenseNames | "all">("all")
    const [statusType, setStatusType] = useState<Statuses | "all">("all");
    const [popup, setPopup] = useState<null | MoreInfo>(null);
    const [search, setSearch] = useState<string>("");

    const { createAlert } = useContext(AlertContext);

    useEffect(() => {
        (async () => {
            await fetchPurchases();
        })()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setTablePage(1);
        fetchPurchases();
        //eslint-disable-next-line
    }, [licenseType, statusType])

    const fetchPurchases = async (from?: string) => {
        const { data: { purchases: purchasesArr, length } } = await axios.get<{ purchases: Purchase[], length: number }>(`/api/purchase/get-purchases/${from === 'search' && search ? `/?search=${search}` : ""}`, {
            params: {
                from: purchases && from === "set_page" ? purchases.length : 0,
                licenseType: licenseType === 'all' ? null : licenseType,
                statusType: statusType === 'all' ? null : statusType
            }
        });
        setPurchases(prev => {
            return prev !== null && from === "set_page" ?
                [...prev, ...purchasesArr] :
                purchasesArr
        });
        setTotalLength(length);
        setLoading(false);
    }

    // changing the page number
    const setPage = async (newPage: number) => {
        purchases && tablePage === Math.ceil(purchases.length / 2) && totalLength > purchases.length && fetchPurchases('set_page');
        setTablePage(newPage);
    }

    const searchPurchases = async () => {
        fetchPurchases('search');
    }

    const onRowClick = async (id: number) => {
        try {
            const { data } = await axios.get<MoreInfo>(`/api/purchase/get-purchase-data/${id}`);
            setPopup(data);
        } catch (err) {
            createAlert("ארעה דגיאה בהבאת הנתונים")
        }
    }

    const refund = async (id: number | undefined) => {
        try {
            if (!id) {
                createAlert("חסרים נתונים");
                return;
            };
            await axios.post('/api/transactions/refund', {
                licenseId: id
            })
        } catch (err) {
            createAlert("ארעה שגיאה");
        }
    }

    return (
        <div className="page purchases">
            <div className="top-page">
                <div className="header">רכישות</div>
            </div>

            <div className="filter">
                <SearchBar
                    value={search}
                    setInput={setSearch}
                    search={searchPurchases}
                    filter={
                        <>
                            <SelectForFilter
                                values={[["all", "כל הסטטוסים"], ...Object.entries(StatusesTranslated)]}
                                value={statusType}
                                setValue={setStatusType as SetStateFilter} />

                            <SelectForFilter
                                value={licenseType}
                                setValue={setLicenseType as SetStateFilter}
                                values={[["all", "כל הרישיונות"], ...Object.entries(LicenseNameTranslated)]} />
                        </>
                    }
                />
            </div>

            <div className="table purchases">
                <GenericTable
                    th={TH.purchases}
                    tr={
                        purchases?.map(p => [
                            <FontAwesomeIcon
                                className={`${p.status}`}
                                icon={['fas',
                                    p.status === Statuses.FAILURE ? 'exclamation-circle' :
                                        (p.status === Statuses.WAITING ? 'clock' :
                                            (p.status === Statuses.SUCCESS ? 'check-circle' : 'sync-alt'))]} />,
                            <div>{p.name}</div>,
                            <div>{p.username}</div>,
                            <div>{p.project_name}</div>,
                            <div>{p.date}</div>,
                            <LicenseTypeChip type={p.license_type} />,
                            <Info className="icon" onClick={() => onRowClick(p.id)} />
                        ]) || []
                    }
                    loading={loading}
                    navigation={true}
                    nextPage={setPage}
                    prevPage={setPage}
                    rowStart={tablePage === 1 ? 0 : (tablePage - 1) * ROWS_NUM}
                    rowsNum={ROWS_NUM}
                    page={tablePage}
                    resultsNum={totalLength} />
            </div>

            <GenericPopUp
                open={!!popup}
                close={() => {setPopup(null)}}
                width="40vw"
                middleOfTop={<h3 className="title">מידע נוסף על רכישה</h3>}
            >
                <div className="more-info">
                    {popupBody.map(item => <div key={item.name} className="row">
                        <span className="title">{item.name}</span>
                        <span>{popup ? item.val?.includes('.') ?
                            popup[item.val.split('.')[0]][item.val.split('.')[1]] :
                            popup?.[item.val as keyof typeof popup] : ""
                        }</span>
                    </div>)}
                    <div className="button">
                        <Button text="החזר תשלום" background="#2a3474" disabled={false} onClick={() => {refund(popup?.license?.id)}} />
                    </div>
                </div>
            </GenericPopUp>
        </div>
    )
}
