import React, { useState, useRef } from 'react'
import { TopBarProps } from '../interfaces/TopBar.interface'
import { DropList } from './DropList'
import { useUser } from '@hilma/auth';
import { Avatar } from '@material-ui/core';
import logo from "../media/logo.svg";
import "../styles/topBar.scss";

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {
    const [dropList, setDropList] = useState<boolean>(false);
    const img = useRef<HTMLImageElement>(null);
    const user = useUser();

    return (
        <div className="topbar">
            <div className="topbar--right">
                {!props.isLoginPage && user ?
                    (<>
                        {props.imgPath ?
                            <img className="user-img" onClick={() => setDropList(prev => !prev)} src={props.imgPath} ref={img} alt="user" />
                            : <Avatar className="user-img" onClick={() => setDropList(prev => !prev)} ref={img} />}
                        <span className="user-name">{user?.name || "Hilma User"}</span>
                        <DropList open={dropList} closeDropList={() => setDropList(false)} exceptRef={img} />
                    </>)
                    : null
                }
            </div>
            <div className="topbar--left">
                <img className="logo" src={logo} alt="logo" />
            </div>
        </div>
    )
}
