import { checkRegex, ERRORS, errorType, formObject, REGEX, checkRegexType, regexType, errorMassagesType, lengthType, barEnum, ADD_EDIT_ENUM, MAX_LICENSES, emailTypes } from '../consts/validation'
import { APPROVE_MAIL_ERRORS_ALERTS, NO_CHANGE_FOR_APPROVING, NO_CHANGE_FOR_MAIL, NO_CHANGE_FOR_SERVER } from '../consts/addEditOrganization'
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import { AddEditProps, ApproveMailErrorsMsg, validationValue } from '../interfaces/AddEditOrganization.interface'
import { CircularProgress, Tooltip, Zoom } from '@material-ui/core'
import AddEditOrganizationLicenses from '../components/AddEditOrganizationLicenses'
import AddEditOrganizationDetails from '../components/AddEditOrganizationDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AlertContext } from "@yaeledri/use-alert"
import { HeadOfPage } from '../components/FormAttributes'
import Axios from 'axios';
import PopUp from '../components/PopUp'
import '../styles/addEditOrganization.scss'


const AddEditOrganization: React.FC<AddEditProps> = (props: AddEditProps) => {
    const [bar, setBar] = useState<barEnum>(barEnum.details)
    const [firstTime, setFirstTime] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [isCustomized, setIsCustomized] = useState(false);
    // const [hasPassword, setHasPassword] = useState<boolean>(false)
    const [originForm, setOriginForm] = useState<formObject | undefined>()
    const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
    const [emailVerified, setEmailVerified] = useState<boolean>(true);
    const [form, setForm] = useState<formObject>({
        orgName: "",
        contactName: "",
        phone: "",
        email: "",
        // password: "",
        autoActive: false,
        expireDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        numOfLicenses: 0,
        activeLicenses: 0,
        baseCode: ""
    })
    const [errors, setErrors] = useState<errorType>({
        orgName: "",
        contactName: "",
        phone: "",
        email: "",
        numOfLicenses: ""
    })

    const { createAlert } = useContext(AlertContext);

    // if there was no change in the form at all
    const noChangesAtForm = useMemo(() => {
        return (originForm &&
            Object.keys(form).every((prop: string) => String(originForm[prop]) === String(form[prop])))
            ?? true
    }, [originForm, form])

    // if we can delete the organization
    const canDelete = useMemo(() => {
        return props.location.state && ((props.location.state.organizationName && originForm) || (!loading && originForm && props.location.state.organizationName));
        // eslint-disable-next-line
    }, [originForm, form])

    // when we cant send requests to the server
    const doNotSendToProjectServer = useMemo(() => {
        return (originForm && NO_CHANGE_FOR_SERVER.every((prop: string) => originForm[prop] === form[prop])) ?? false
    }, [originForm, form])

    // for each mail- wether we can or cant send it
    const noChangeForMail = useMemo(() => {
        const data = (originForm && !NO_CHANGE_FOR_MAIL.every((prop: string) => originForm[prop] === form[prop] && originForm[prop] !== "")) ?? true;
        const approve = (emailVerified || (originForm && (!originForm["orgName"] || !originForm["baseCode"] || !originForm["numOfLicenses"]))) ?? true;
        return { data, approve }
    }, [originForm, form, emailVerified]);

    // get the data of the project
    useEffect(() => {
        if (!props.location.state) {
            createAlert('ארעה שגיאה');
        }
        if (!props.location.state?.organizationId) {
            setOriginForm({ ...form });
            return;
        }
        (async () => {
            try {
                setLoading(true)
                const { data } = await Axios.get(`/api/license/get-license-details/${props.location.state.organizationId}`)
                // console.log('data: ', data);
                // setHasPassword(data.user?.password !== "0");
                if (data.license_type === "customized") setIsCustomized(true);
                setEmailVerified(data.email_verified);
                const formsCopy = {
                    ...form,
                    autoActive: Boolean(data.active_auto_state),
                    orgName: data.full_name,
                    contactName: data.user?.name ?? "",
                    phone: data.user?.phone ?? "",
                    email: data.user?.username ?? "",
                    expireDate: new Date(data.expire_date),
                    numOfLicenses: data.given_number_of_licenses,
                    baseCode: data.base_code,
                    activeLicenses: data.activeLicenses || "0"
                }
                setOriginForm(formsCopy);
                setForm(formsCopy)
            } catch (error: any) {
                if (error?.data?.error === "NO_LICENSE") {
                    createAlert(`עמותת '${props.location.state.organizationName}' לא קיימת`);
                    goBack();
                    return;
                }
                createAlert("אירעה שגיאה בהבאת פרטי העמותה")
            }
            setLoading(false)
        })()
        //eslint-disable-next-line
    }, [props.location?.state?.organizationId])

    // generate code
    useEffect(() => {
        if (!firstTime || (firstTime && bar !== barEnum.licenses) || props.location.state?.organizationId || form.baseCode) return
        (async () => {
            setLoading(true)
            await generateCode()
            setLoading(false)
        })()
        //eslint-disable-next-line
    }, [bar])

    const generateCode = async () => {
        try {
            const { data } = await Axios.get('/api/license/get-new-base-code')
            onChange({ target: { value: String(data), name: "baseCode" } })
            setFirstTime(false)
            return String(data)
        } catch (error) {
            createAlert("אירעה שגיאה ביצירת קוד הרישיון")
        }
    }

    const validation = ({ value, name }: { value: validationValue, name: string }): boolean => {
        if (name === "autoActive") return true;
        value = typeof value === 'string' ? value.trim() : value
        // check if it is not empty
        if ((typeof value === "string" && !value.length)) {
            setErrors(prev => { return { ...prev, [name]: ERRORS.empty } })
            return false
        }
        // LENGTH:
        const lengthObject = checkRegex[name as keyof checkRegexType].length;
        if (lengthObject) {
            const inRange = (Object.keys(lengthObject))!.every(minMax => {
                // checking if there if such field 
                if (typeof value === "string" && lengthObject && lengthObject![minMax as keyof lengthType]) {
                    if ((minMax === "max" && lengthObject![minMax as keyof lengthType]![0] < value.length)
                        || (minMax === "min" && lengthObject![minMax as keyof lengthType]![0] > value.length)) {
                        // if the the value doesn't in range
                        setErrors(prev => {
                            return { ...prev, [name]: ERRORS[lengthObject![minMax as keyof lengthType]![1]] }
                        })
                        return false
                    }
                }
                return true
            })
            if (!inRange) return false
        }

        // check all the regexes for each field
        const succeededAllRegexes = checkRegex[name as keyof checkRegexType].regexes.every(regexAndMassage => {
            const failedInCurrentRegex = !REGEX[regexAndMassage[0] as keyof regexType].test('' + value)
            if (failedInCurrentRegex) {
                setErrors(prev => {
                    return { ...prev, [name]: ERRORS[regexAndMassage[1] as keyof errorMassagesType] }
                })
                return false
            }
            return true
        })
        // if one of the regexes failed, return
        if (!succeededAllRegexes) return false

        // we delete all the masseges that left
        setErrors(prev => {
            return { ...prev, [name]: "" }
        })
        return true
    }

    const createDateFormat = (dateStr: Date | null) => {
        return dateStr?.toLocaleDateString('he-IL', { year: 'numeric' }) + "-" +
            dateStr?.toLocaleDateString('he-IL', { month: '2-digit' }) + "-" +
            dateStr?.toLocaleDateString('he-IL', { day: '2-digit' })
    }

    const finalValidation = () => {
        let allFieldsValid = true;
        const keys: string[] = [];
        (Object.keys(form)).forEach((key: string) => {
            if (!(key === "expireDate" || key === "activeLicenses" || key === "baseCode"/*  || (key === "password" && hasPassword && !form.password) */)) {
                const isValid = validation({ value: form[key as keyof formObject], name: key })
                if (!isValid) keys.push(key)
                allFieldsValid = allFieldsValid && isValid
            }
        })

        if (bar === barEnum.details && keys.includes("numOfLicenses")) {
            createAlert('ישנם שדות לא תקינים ב"רישיונות"')
        }
        const onFieldHasError = ["orgName", "contactName", "phone", "email", "expireDate"]
            .map((key1: string) => keys.includes(key1))
            .reduce((key1, key2) => key1 || key2)

        if (bar === barEnum.licenses && onFieldHasError) {
            createAlert('ישנם שדות לא תקינים ב"פרטים אישיים"')
        }

        return allFieldsValid
    }

    const postOrg = async () => {
        if (noChangesAtForm) {
            createAlert("לא נעשו שינויים", "warning")
            return
        }

        if (!finalValidation()) return
        try {
            setLoading(true)
            const code = form.baseCode || await generateCode()
            const route = `/api/license/${!props.location.state.organizationId ? 'create-license' : 'edit-license'}`
            await Axios.post(route, {
                ...form,
                numOfLicenses: Number(form.numOfLicenses),
                id: props.location.state.organizationId ? String(props.location.state.organizationId) : undefined,
                expireDate: createDateFormat(form.expireDate),
                baseCode: code,
                projectId: props.location.state.projectId,
                sendToProjectServer: !doNotSendToProjectServer
            })
            createAlert(`העמותה ${props.location.state.organizationId ? "עודכנה" : "נשמרה"} בהצלחה`, "success")
            goBack()
        } catch (error: any) {
            let errorString = "";
            switch (error?.data?.error) {
                case 'LICENSE_WITH_SAME_NAME':
                    errorString = "ישנה עמותה עם שם זהה";
                    break;
                case 'USERNAME_ALREADY_EXISTS':
                    errorString = "שם המשתמש תפוס";
                    break;
                case "NOT_ALLOWED_REMOTE":
                    errorString = "גישה לפרוקיט נדחתה";
                    break;
                case "GENERAL_ERROR":
                    errorString = "ארעה שגיאה, אנא נסו שנית מאוחר יותר";
                    break;
                case "ERROR_UPDATE":
                    errorString= "אירעה שגיאה בעדכון העמותה";
                    break;
                default:
                    errorString = "אירעה שגיאה בשמירת העמותה";
                    break
            }
            createAlert(errorString);
        }
        setLoading(false)
    }

    const deleteError = (field: string) => {
        setErrors(prev => {
            prev[field] = ""
            return { ...prev }
        })
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement> | { target: { value: any, name: string } }) => {
        const { value, name } = e.target;
        if (name === 'numOfLicenses' && value > MAX_LICENSES) {
            createAlert(`מספר הרשיונות המקסימלי הוא ${MAX_LICENSES}`, 'error', 3000);
            return;
        }
        setForm(prev => {
            return { ...prev, [name]: value }
        })
    }

    const onDelete = async () => {
        try {
            // if the organization isn't created yet - add mode
            if (!props.location.state?.organizationId) return
            await Axios.delete(`/api/license/delete-license`, {
                data: {
                    projectId: props.location.state.projectId,
                    organizationId: props.location.state.organizationId
                }
            })
            createAlert("המחיקה הסתיימה בהצלחה", "success")
            goBack()
        } catch (err) {
            createAlert("אירעה שגיאה במחיקת העמותה")
        }
    }

    // the change of the "auto" input (checkbox)
    const autoChange = (e: (ChangeEvent<HTMLInputElement> | { target: { name: string, checked: boolean } })) => {
        const { name, checked } = e.target;
        setForm(prev => {
            return { ...prev, [name]: checked }
        })
    }

    const goBack = () => {
        props.history.goBack()
    }

    const togglePopup = (open: boolean) => () => {
        if (!canDelete) return;
        setOpenDeletePopUp(open)
    }

    // errors of 'send approve organization mail' button
    const approveMailErrors = (err: any) => {
        const msg = err?.data?.error;
        if (msg as keyof ApproveMailErrorsMsg) return createAlert(APPROVE_MAIL_ERRORS_ALERTS[msg]);
        createAlert('ארעה שגיאה בנסיון לשלוח מייל');
    }

    const sendMail = async (type: emailTypes) => {
        try {
            if (type === 'approve') await sendApproveMail();
            if (type === 'data') await sendDataMail();
        } catch (err) {
            if (type === 'approve') return approveMailErrors(err);
            return createAlert('ארעה שגיאה בנסיון לשלוח מייל')
        }
    }

    const sendDataMail = async () => {
        if (NO_CHANGE_FOR_MAIL.every((prop: string) => !errors[prop] && form[prop])) {
            const { email, orgName, contactName, phone, expireDate, numOfLicenses } = form;
            await Axios.post('/api/mails/organization-admin-data', {
                mail: email,
                org_name: orgName,
                name: contactName,
                phone,
                num_of_licenses: numOfLicenses,
                expire_date: expireDate
            });
            return createAlert('שליחת הפרטים במייל בוצעה בהצלחה', 'success');
        }
        createAlert('חלק מהמידע אינו תקין או חסר');
    }

    const sendApproveMail = async () => {
        const canSend = NO_CHANGE_FOR_APPROVING.every((prop: string) => {
            return errors[prop] !== undefined ? errors[prop] === "" : true && form[prop]
        });
        if (canSend) {
            const { orgName, numOfLicenses, baseCode } = form;
            await Axios.post('/api/license/resend-code-approving-mail', {
                orgName,
                numOfLicenses,
                baseCode
            });
            return createAlert('המייל נשלח בהצלחה', 'success');
        }
        createAlert('חלק מהמידע אינו תקין או חסר');
    }

    return (
        <div className="page addEditOrganization">
            <HeadOfPage
                arrowFunction={goBack}
                header={`${props.location.state?.projectName || "פרויקט"} / ${props.location.state?.organizationName || "עמותה חדשה"}`}
                endOfRowButtonType={"save"}
                endOfRowButtonFunction={postOrg}
                endOfRowButtonText={props.location.state?.organizationId ? "עדכון" : "שמירה"}
                endOfRowButtonDisabled={noChangesAtForm} />

            <div className="details">
                <div className="bar-container">
                    <ul className="bar">
                        <li className={bar === barEnum.details ? 'underline' : ''} onClick={() => { setBar(barEnum.details) }}>פרטים</li>
                        <li className={bar === barEnum.licenses ? 'underline' : ''} onClick={() => { setBar(barEnum.licenses) }}>רישיונות</li>
                    </ul>
                    <Tooltip style={{display: isCustomized ? "" : "none" }} TransitionComponent={Zoom} title='מחיקת עמותה'>
                        <span className="trash-icon" style={{ display: isCustomized ? 'flex' : "none", justifyContent: "center" }}>
                            <FontAwesomeIcon
                                icon={["fas", "trash"]}
                                style={{ opacity: canDelete ? 1 : 0.3 }}
                                onClick={togglePopup(true)} />
                        </span>
                    </Tooltip>

                </div>
                <div
                    style={loading ? { position: 'relative' } : {}}>
                    {loading &&
                        <CircularProgress size='60px' style={{ color: "#E1663C", position: 'absolute', top: '20vh', left: 'calc(50% - 30px)', marginBottom: '10vh', zIndex: 30/* , zIndex: '30' */ }} />
                    }
                </div>
                <div className="form-container" style={loading ? { opacity: "0.6" } : {}}>
                    {
                        bar === barEnum.details ?
                            <AddEditOrganizationDetails
                                mode={props.location?.state?.organizationId ? ADD_EDIT_ENUM.edit : ADD_EDIT_ENUM.add}
                                // hasPassword={hasPassword}
                                autoChange={autoChange}
                                disabled={loading}
                                errors={errors}
                                formValues={form}
                                onChange={onChange}
                                deleteError={deleteError}
                                sendMail={{ disabledMail: noChangeForMail, onEmailClick: sendMail }}
                                onBlur={e => validation(e.target)} />
                            :
                            bar === barEnum.licenses ?
                                <AddEditOrganizationLicenses
                                    autoChange={autoChange}
                                    disabled={loading}
                                    errors={errors}
                                    formValues={form}
                                    onChange={onChange}
                                    onBlur={e => validation(e.target)} /> :
                                <></>
                    }
                </div>
            </div>


            <PopUp
                title="מחיקת עמותה"
                open={openDeletePopUp}
                close={togglePopup(false)}
                deleteing={onDelete}>

                <div >האם את\ה בטוח\ה שאת\ה רוצה למחוק את העמותה?</div>

            </PopUp>

        </div>)
}

export default AddEditOrganization