import { ApproveMailErrorsAlerts, partOfTheFields } from './../interfaces/AddEditOrganization.interface';

export const NO_CHANGE_FOR_SERVER: partOfTheFields = ["contactName", "phone", "email"/* , "password" */];
export const NO_CHANGE_FOR_MAIL: partOfTheFields = ["contactName", "phone", "email", "orgName", "expireDate", "numOfLicenses"];
export const NO_CHANGE_FOR_APPROVING: partOfTheFields = ["baseCode", "numOfLicenses", "orgName"];

export const APPROVE_MAIL_ERRORS_ALERTS: ApproveMailErrorsAlerts = {
    AlreadyApproved: "הרשיון כבר אושר",
    NoOrganization: "מידע לא תקין- לא קיים קוד כזה",
    GeneralError: "ארעה שגיאה"
}