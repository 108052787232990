import { inputArr, radioButtons, OpenPopup, OpenPopupType, ProjectData, projectsProps } from '../interfaces/Project.interface'
import React, { useState, useMemo, useContext } from 'react'
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import { AddIcon, Button, Input } from '../components/FormAttributes';
import { useCopyToClipboard } from 'react-use'
import { HEBREW_LOGIN_TYPE } from '../consts/project';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsyncEffect } from '@hilma/tools';
import { AlertContext } from "@yaeledri/use-alert"
import { RadioButton } from '../components/FormAttributes';
import { domainRegex } from '../consts/validation';
import { LoginType } from '../enums/LoginType.enum';
import { ROWS_NUM } from '../consts/rowsNum';
import { TH } from '../consts/tableHeaders'
import GenericTable from '../components/GenericTable';
import SearchBar from '../components/SearchBar';
import PopUp from '../components/PopUp';
import Axios from "axios";
import '../styles/page.scss';

const Projects: React.FC<projectsProps> = (props: projectsProps) => {
    // general projects data
    const [projectsArr, setProjects] = useState<ProjectData[] | undefined>(undefined);
    const [projectsArrayLength, setProjectsArrayLength] = useState<number>(5);
    const [tablePage, setTablePage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    // project popup
    const [openCopyTooltip, setOpenCopyTooltip] = useState(false);
    const [projectBody, setProjectBody] = useState<ProjectData>({ name: "", domain: "", clients_num: '', id: -1, login_type: LoginType.REGULAR });
    const [projectErrors, setProjectErrors] = useState({ name: "", domain: "" });
    const [loadPopUp, setLoadPopUp] = useState(false);
    const [tokenKey, setTokenKey] = useState<string>("");
    const [open, setOpen] = useState<OpenPopup>({ value: false, type: OpenPopupType.INSERT });
    // search
    const [searchInput, setInput] = useState<string>("");

    const { createAlert } = useContext(AlertContext);
    const [, copyToClipboard] = useCopyToClipboard()

    // get projects form the server when page loads
    useAsyncEffect(async () => {
        try {
            await fetchProjects();
            setLoading(false);
        } catch (err) {
            createAlert("ארעה שגיאה בהבאת הפרויקטים מהשרת");
            setLoading(false);
        }
    }, [])

    // organize the project's data into table
    const projectsTr = useMemo(() => {
        return projectsArr?.map((tr: ProjectData) =>
            [
                <div>{tr.name}</div>,
                <div>{tr.clients_num}</div>,
                <div>{HEBREW_LOGIN_TYPE[tr.login_type]}</div>,
                <div>{tr.domain}</div>,
                <div onClick={(e) => e.stopPropagation()}>
                    <FontAwesomeIcon
                        onClick={(e) => { onEditClick(+tr.id, tr.name, tr.domain) }}
                        icon={["fas", "pen"]} />
                </div>,
                <div onClick={(e) => e.stopPropagation()}>
                    <FontAwesomeIcon
                        onClick={() => onDeleteClick(+tr.id)}
                        icon={["fas", "trash"]} />
                </div>
            ]
        ) || null;
        // eslint-disable-next-line
    }, [projectsArr])

    // fetch the projects
    async function fetchProjects(from?: string | undefined, search?: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.get(`/api/project/get-all/${projectsArr && from === "set_page" ? projectsArr.length : 0}${from === 'search' && search ? `/?search=${search}` : ""}`);
                console.log('response: ', response);
                setProjects(prev => prev && from === 'set_page' ? [...prev, ...response.data.projects] : [...response.data.projects]);
                setProjectsArrayLength(response.data.projectsLength);
                resolve(1);
            } catch (err) {
                createAlert("ארעה שגיאה בהבאת הפרויקטים מהשרת");
                setProjects([]);
                setProjectsArrayLength(0);
                reject(0);
            }
        });
    }

    // generic server request
    const genericServerRequest = async (url: string, body: any, msg: string, errorMsg: string) => {
        try {
            await Axios.post(url, body);
            closePopup();
            setPage(1);
            fetchProjects();
            createAlert(msg, "success");
            setProjectBody({ name: "", domain: "", clients_num: '', id: -1, login_type: LoginType.REGULAR });
            setTokenKey("");
        } catch (err: any) {
            if (err.data.error === "ER_DUP_ENTRY_DOMAIN") createAlert("כתובת הדומיין כבר משוייכת לפרויקט אחר");
            else if (err.data.error === "ER_DUP_ENTRY_NAME") createAlert("כבר קיים פרויקט עם שם כזה");
            else createAlert(errorMsg);
        }
    }

    // calling the server to add project
    async function addProject() {
        const valid = validateServerCalls("add");
        if (!valid) return;
        setLoadPopUp(true);
        const { name, domain, login_type } = projectBody;
        const body = {
            name,
            domain,
            token_key: tokenKey,
            login_type
        }
        await genericServerRequest('/api/project/create', body, "הפרויקט נשמר בהצלחה", "ארעה שגיאה בהוספת הפרויקט");
        setLoadPopUp(false);
    }

    // calling the server to edit a project
    async function editProject() {
        const valid = validateServerCalls("edit");
        if (!valid) return;
        setLoadPopUp(true);
        const { name, domain, id, login_type } = projectBody;
        const ourData = projectsArr && projectsArr.find(p => +p.id === id);
        if (!ourData) {
            createAlert("ארעה שגיאה, אנא נסו שנית מאוחר יותר");
            return;
        }
        let ourBody = { id: id }
        if (ourData.domain !== domain) ourBody["domain"] = domain;
        if (ourData.name !== name) ourBody["name"] = name;
        if (ourData.login_type !== login_type) ourBody["login_type"] = login_type;
        if (tokenKey) ourBody["token_key"] = tokenKey;
        await genericServerRequest('/api/project/edit', ourBody, "הפרטים נשמרו בהצלחה", "ארעה שגיאה בעריכת הפרויקט");
        setLoadPopUp(false);
    }

    // calling the server to delete a project
    async function deleteProject() {
        const valid = validateServerCalls("delete");
        if (!valid) return;
        setLoadPopUp(true);
        const { id } = projectBody;

        try {
            await Axios.delete(`/api/project/delete`, { data: { id } });
            closePopup();
            setPage(1)
            fetchProjects();
            setProjectBody({ name: "", domain: "", clients_num: '', id: -1, login_type: LoginType.REGULAR });
            createAlert("הפרויקט נמחק בהצלחה", "success")
        } catch (err) {
            createAlert("ארעה שגיאה במחיקת הפרויקט");
            setPage(1)
        }
        setLoadPopUp(false);
    }

    function validateServerCalls(type: "add" | "edit" | "delete") {
        const { id, domain, name, login_type } = projectBody;
        if (type === "edit" || type === "add") {
            if (!projectBody.login_type) {
                createAlert("סוג התחברות חייב להכיל ערך");
                return false;
            }
            if (!Object.values(LoginType).includes(projectBody.login_type)) {
                createAlert("תוכן לא חוקי של סוג התחברות");
                return false;
            }
            if (projectErrors.domain || projectErrors.name) {
                createAlert("לא ניתן לשמור עד שכל המידע יהיה תקין")
                return false;
            }
            for (const item of Object.entries(projectBody)) {
                if (item[0] !== "id" && (!item[1] || typeof item[1] !== "string") && item[0] !== 'clients_num') {
                    createAlert("השדות חייבים להכין ערכים");
                    return false;
                }
            }
        }
        if (type === "edit" || type === "delete") {
            if (id === -1 || isNaN(id)) {
                createAlert("ארעה שגיאה, אנא נסו שנית");
                return false;
            }
            if (type === "edit") {
                if (tokenKey) return true
                const ourData = projectsArr && projectsArr.find(p => +p.id === id);
                if (ourData && (ourData.domain === domain && ourData.name === name && ourData.login_type === login_type)) {
                    createAlert("שום מידע לא השתנה");
                    return false; // no information has changed
                }
            }
        }
        return true
    }

    // when clicking on a row
    const onRowClick = (e: React.FormEvent<HTMLInputElement>, i: number, td: any) => {
        e.preventDefault();
        if (projectsArr) {
            props.history.push({ pathname: '/projects/organizations', state: { projectId: projectsArr[i].id, projectName: projectsArr[i].name } });
        }
    }

    // when clicking on edit icon
    function onEditClick(id: number, name: string, domain: string) {
        setProjectBody(prev => {
            return {
                ...prev,
                name,
                domain,
                id
            }
        });
        openPopup(OpenPopupType.UPDATE);
    }

    // when clicking on delete icon
    function onDeleteClick(id: number) {
        setProjectBody(prev => { return { ...prev, id } })
        openPopup(OpenPopupType.DELETE);
    }

    // when searching a project
    async function searchProject(value: string) {
        try {
            await fetchProjects('search', value);
        } catch (err) {
            createAlert('אירעה שגיאה בחיפוש פרויקט')
        }
        setTablePage(1);
    }

    // when clicking on close icon of the popup
    function closePopup() {
        setOpen((prev) => {
            return { ...prev, value: false }
        })
        setProjectBody({ name: "", domain: "", clients_num: '', id: -1, login_type: LoginType.REGULAR });
        setProjectErrors({ domain: "", name: "" });
        setTokenKey("");
    }

    // opening the popup
    function openPopup(mode: OpenPopupType) {
        setOpen({ value: true, type: mode });
    }

    // when blurring the name
    function onNameBlur() {
        if (projectBody.name === "" && projectErrors.name === "") {
            setProjectErrors(prev => { return { ...prev, name: "שם הפרויקט חייב להכיל ערך" } });
        }
    }

    // when changing the name
    function onNameChange(e: React.FormEvent<HTMLInputElement>) {
        // format- every letter, number, -.
        const nameRegex = /^[\u0590-\u05FF a-zA-Z0-9 \-']{1,}$/;
        const { value } = e.currentTarget;
        if (value.length < 3) {
            setProjectErrors(prev => { return { ...prev, name: "שם פרוייקט חייב להיות באורך של שלוש או יותר אותיות" } });
        } else if (!nameRegex.test(value)) {
            setProjectErrors(prev => { return { ...prev, name: "שם הפרויקט יכול להכיל רק מספרים, אותיות בעברית, אותיות באנגלית גרש ומקף." } });
        } else {
            setProjectErrors(prev => { return { ...prev, name: "" } });
        }
        setProjectBody(prev => { return { ...prev, name: value } });
    }

    // when blurring the domain input
    function onDomainBlur() {
        if (!domainRegex.test(projectBody.domain)) {
            if (projectErrors.domain === "") {
                if (projectBody.domain !== "") {
                    setProjectErrors(prev => { return { ...prev, domain: "הדומיין לא תואם את הפורמט הרצוי: 'https://NAMEOFDOMAIN.com/api'" } })
                } else {
                    setProjectErrors(prev => { return { ...prev, domain: "הדומיין חייב להכיל ערך" } })
                }
            }

        }
    }

    // when changing the domain
    function onDomainChange(e: React.FormEvent<HTMLInputElement>) {
        setProjectErrors(prev => { return { ...prev, domain: "" } });
        const domainRegex = /^[a-zA-Z0-9 -.:/]*$/;
        const { value } = e.currentTarget;
        if (!domainRegex.test(value)) {
            setProjectErrors(prev => { return { ...prev, domain: "הדומיין יכול להכיל רק אותיות באנגלית, מספרים, מקף, נקודותיים, נקודה או סלש." } });
        } else {
            setProjectErrors(prev => { return { ...prev, domain: "" } });
        }
        setProjectBody(prev => { return { ...prev, domain: value } });
    }

    function onRadioChange(e: React.FormEvent<HTMLInputElement>) {
        const { id } = e.currentTarget;
        const newId = id as unknown as LoginType;
        if (Object.values(LoginType).includes(newId)) {
            setProjectBody(prev => ({...prev, login_type: newId}))        
        }
    }

    // changing the page number
    async function setPage(newPage: number) {
        projectsArr && tablePage === Math.ceil(projectsArr.length / ROWS_NUM) && projectsArrayLength > projectsArr.length && fetchProjects('set_page')

        setTablePage(newPage);
    }

    async function onAddClick() {
        createCode(() => openPopup(OpenPopupType.INSERT))
    }

    async function createCode(cb?: () => void) {
        setLoadPopUp(true)
        if (typeof cb === 'function') {
            cb();
        }
        try {
            const { data } = await Axios.get('/api/project/generate-project-token');
            setTokenKey(data);
        } catch (error) {
            createAlert("אירעה שגיאה ביצירת מפתח לפרויקט")
        }
        setLoadPopUp(false);
    }

    const nameOfProject = useMemo(() => {
        if (projectBody.id === null) return;
        const currentProject = projectsArr?.find(p => +p.id === projectBody.id);
        return currentProject?.name;
    }, [projectBody.id, projectsArr])

    const copyToBoard = () => {
        // navigator.clipboard.writeText(tokenKey);
        copyToClipboard(tokenKey)
        setOpenCopyTooltip(true)
    }

    const closeTooltip = () => setOpenCopyTooltip(false)

    return (
        <div className="page projects">
            <div className="top-page">
                <div className="header">פרויקטים</div>
                <AddIcon
                    onClick={onAddClick}
                />
            </div>
            <SearchBar
                value={searchInput}
                setInput={setInput}
                search={searchProject} />

            <div className="table">
                <GenericTable
                    th={TH.projects}
                    tr={projectsTr}
                    loading={loading}
                    navigation={true}
                    onRowClick={onRowClick}
                    nextPage={setPage}
                    prevPage={setPage}
                    rowStart={tablePage === 1 ? 0 : (tablePage - 1) * ROWS_NUM}
                    rowsNum={ROWS_NUM}
                    page={tablePage}
                    resultsNum={projectsArrayLength} />
            </div>

            <PopUp
                title={open.type === "insert" ? "הוספת פרויקט" : open.type === "delete" ? "מחיקה" : `עריכת פרויקט ${nameOfProject || ""}`}
                open={open.value}
                keep={open.type === "insert" ? addProject : open.type === "update" ? editProject : undefined}
                deleteing={open.type === "delete" ? deleteProject : undefined}
                close={closePopup}
                loading={loadPopUp}
            >
                <>
                    {open.type === "delete" ?
                        <div key={1} className="popup-body">
                            <div>האם את\ה בטוח\ה שאת\ה רוצה למחוק את הפרויקט </div>
                            <br />
                            <div className='strong'>
                                {nameOfProject || ""}?
                            </div>
                        </div>
                        :
                        (inputArr.map(({ text, attribute, type }, p) =>
                            <div key={p} className="popup-body">
                                <div className="popup-input">
                                    <div className="popup-input-name">{text}</div>
                                    <div className="popup-input-div">
                                        {attribute === "login_type" ? radioButtons.map(({ id }) =>
                                            <RadioButton
                                                value={projectBody[attribute]}
                                                name={HEBREW_LOGIN_TYPE[id]}
                                                id={id}
                                                onChange={onRadioChange}
                                            />) : <Input
                                                value={projectBody[attribute]}
                                                onBlur={attribute === "domain" ? onDomainBlur : onNameBlur}
                                                placeHolder={text}
                                                type={type}
                                                onChange={attribute === "domain" ? onDomainChange : onNameChange}
                                                error={!!projectErrors[attribute]}
                                            />}
                                    </div>
                                </div>
                                <div className="popup-error">{projectErrors[attribute]}</div>
                            </div>
                        ))
                    }
                    <div className="popup-body">
                        {
                            open.type !== 'delete' ?
                                <div className="popup-input">
                                    <div className="popup-input-name">{"מפתח פרויקט"}</div>
                                    <div className="popup-input-div">
                                        <Input
                                            value={tokenKey}
                                            className="height-and-width"
                                            placeHolder="---"
                                            type="text"
                                            disabled={true}
                                            onChange={() => { }}
                                            icon={
                                                <ClickAwayListener onClickAway={closeTooltip}>
                                                    <Tooltip
                                                        onClose={closeTooltip}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        open={openCopyTooltip}
                                                        title='מפתח פרויקט הועתק'>
                                                        <span className={`trash-icon ${tokenKey.length ? "" : "disabled"}`} style={{ display: 'flex', justifyContent: "center" }}>
                                                            <FontAwesomeIcon
                                                                onClick={tokenKey.length ? copyToBoard : undefined}
                                                                icon={["fas", "copy"]} />
                                                        </span>
                                                    </Tooltip>
                                                </ClickAwayListener>

                                            }
                                        />
                                    </div>
                                </div>
                                : null
                        }
                        {open.type === 'update' ?
                            <Button
                                text={"יצירת מפתח פרויקט חדש"}
                                onClick={() => createCode()}
                                disabled={!!tokenKey}
                            />
                            :
                            <></>
                        }
                    </div>
                    <div key={"token-key"} className="popup-body">
                        <div className="popup-alert" style={!tokenKey ? { height: '0px', color: 'white' } : {}}>
                            <strong className='popup-alert-bold'>שים/י לב, </strong>
                            ברגע שתאשר/י את {open.type === "insert" ? "יצירת" : "עדכון"} הפרויקט לא יהיה ניתן לראות שוב את מפתח הפרויקט,
                            <br />
                            עליו להיות שמור בקבצי הפרויקט. אנא דאג/י שאכן כך יעשה
                        </div>
                    </div>
                </>
            </PopUp>
        </div>
    );
}
export default Projects;