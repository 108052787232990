import React from "react";
import { NotFoundProps } from "../interfaces/NotFound.interface";
import { useHistory } from "react-router";
import notFound from '../media/not-found.png';
import '../styles/codeVerification.scss';

export const NotFound: React.FC<NotFoundProps> = ({ img, text, canGoBack = true }: NotFoundProps) => {
    const history = useHistory();

    const backToHome = () => history.push('/');

    return <div className='not-found'>
        <img src={img || notFound} alt='NOT_FOUND' />
        <h2>{text || "שגיאה! העמוד אותו ביקשת לא קיים"}</h2>
        {canGoBack ? <p>לחזרה לעמוד הראשי לחץ <span onClick={backToHome} className='link'>כאן</span></p> : null}
    </div>
}
