import React, { useState } from 'react';
import { InputFormaterProps } from '../interfaces/InputFormater.interface'
import { REGEX } from '../consts/validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// login input
const InputFormater: React.FC<InputFormaterProps> = ({ inputName, placeholder, stateValue, inputType, handleChange, generalError, ignoreGeneralError }: InputFormaterProps) => {
    const [error, setError] = useState<string>('');

    const showOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!stateValue || e.target.value === "") {
            setError('שדה חובה')
        } else {

            switch (inputName) {
                case 'full_name':
                    if (!/([א-ת'-]{2,}[ ]{1,1}[א-ת'-]{2,}){1,}/.test(stateValue)) {
                        if (!/[א-ת'-]/.test(stateValue)) {
                            setError('ניתן להכניס שם בעברית בלבד ')
                        } else {
                            setError('נא להכניס שם פרטי ושם משפחה')
                        }
                    } else {
                        setError('')
                    }
                    break;
                case 'password':
                    if (stateValue.length < 6 || stateValue.length > 20) {
                        setError('הסיסמא צריכה להיות בין 6-20 תווים')
                    } else {
                        setError('')
                    }
                    break;
                case 'username':
                    ignoreGeneralError && ignoreGeneralError()
                    if (!REGEX.email.test(stateValue)
                    ) {
                        setError('אימייל לא חוקי')
                    } else {
                        setError('')
                    }
                    break;
                case 'verPassword':
                    ignoreGeneralError && ignoreGeneralError()
                    break;
                default:
                    setError('')
                    break;
            }
        }
    }

    return (

        <>
            <input
                className={`input-register  ${inputName === 'username' ? 'email' : inputName} ${error || generalError ? "error" : ""} `}
                type={inputType}
                name={inputName}
                placeholder={placeholder}
                value={stateValue}
                onChange={handleChange} required
                onBlur={showOnBlur} />
            <div className='error-message'>
                {error || generalError ? <FontAwesomeIcon icon="info-circle" className='info-icon' /> : null}
                {error || generalError || ""}
            </div>
        </>
    )


}
export default InputFormater;