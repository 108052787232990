import { InputBase, Select } from '@material-ui/core';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

export const CustomizedInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            marginRight: '2vw',
            width: "20%",
            height: "100%",
        },
        input: {
            borderRadius: "8px",
            backgroundColor: '#f1f0f0',
            color: '#64646d',
            fontFamily: "Heebo",
            height: '100%',
            paddingTop: 0,
            paddingBottom: 0,
            display: "flex",
            alignItems: "center"
        }
    }),
)(InputBase);

export const CustomizedSelect = withStyles((theme: Theme) =>
    createStyles({
        selectMenu: {
            backgroundColor: '#f1f0f0',
            color: '#64646d',
            fontFamily: "Heebo",
        }
    }),
)(Select);