import React from 'react';
import { InputProps, formFieldProps, ButtonProps, ArrowProps, AddIconProps, CheckboxProps, RadioButtonProps, headOfPageProps, IconProps } from '../interfaces/FormAttributes.interface'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/formAttributes.scss";

// generic input component
export const Input: React.FC<InputProps> = ({ onBlur, onChange, placeHolder, value, type, className, id, disabled, icon }: InputProps) => {
    const input: JSX.Element = <>
        <input
            className={"form--input " + (!icon ? (className || "") : "")}
            autoComplete="new-password"
            id={id || ""}
            name={id || ""}
            type={type || "text"}
            placeholder={placeHolder || ""}
            value={value}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            maxLength={80}
        />
        <div className="form--icon">
            {icon}
        </div>
    </>
    return (
        icon ?
            <div className={"form--icon-and-input " + (icon ? "padding " : "") + (className || "")} >
                {input}
            </div> : input
    )
}

// generic for field component (input + label + error)
export const FormField: React.FC<formFieldProps> = ({ id, altInput, fieldLabel, error, placeHolder, value, type, disabled, onChange, onBlur, icon, israeliPhone }: formFieldProps) => {
    return (
        <div className="label-input" style={altInput ? { padding: "0 0" } : {}}>
            <label htmlFor={id} className="label">{fieldLabel}</label>
            <div className="input-error">
                {altInput ?
                    <span>{altInput}</span> :
                    <span className={(id === 'phone' && israeliPhone) ? 'phone-input' : ''}>
                        <Input
                            className={error ? "inputError" : ""}
                            placeHolder={placeHolder}
                            value={value}
                            type={type}
                            id={id}
                            disabled={disabled}
                            onChange={onChange}
                            onBlur={onBlur}
                            icon={icon}
                        />
                        {(id === 'phone' && israeliPhone) ? <span className='phone-input--start'>972+</span> : null}
                    </span>
                }
                <div className={(!error ? "empty " : "") + "error"}>{error}</div>
            </div>
        </div>
    )
}

// generic button component
export const Button: React.FC<ButtonProps> = ({ onClick, color, background, text, width, disabled }: ButtonProps) => {
    return (
        <div
            className="form--button"
            style={{
                backgroundColor: background || "#E1663C",
                color: color || "white",
                width: width ? width + "%" : "",
                opacity: disabled ? "0.3" : "1"
            }}
            onClick={!disabled ? onClick : () => { return }}>
            {text || "button"}
        </div>
    )
}

// back arrow component
export const BackArrow: React.FC<ArrowProps> = ({ goBack }: ArrowProps) => {
    return (
        <FontAwesomeIcon
            onClick={goBack}
            className='arrow-right'
            icon={['fas', 'arrow-right']} />
    )
}

// add icon component
export const AddIcon: React.FC<AddIconProps> = ({ onClick, disabled }: AddIconProps) => {
    return (
        <div className={`circle-button-plus ${disabled ? "disabled" : ""}`} onClick={onClick}>
            <FontAwesomeIcon
                className='button-plus'
                icon={['fas', 'plus']} />
        </div>
    )
}

// title of page in head (organization page)
export const HeadOfPage: React.FC<headOfPageProps> = ({ arrowFunction, header, endOfRowButtonType, endOfRowButtonFunction, endOfRowButtonText, endOfRowButtonDisabled }: headOfPageProps) => {
    return (
        <div className="top-page">
            <div className="header">
                {arrowFunction ?
                    <BackArrow goBack={arrowFunction} /> :
                    null}
                <div className="header--content">{header}</div>
            </div>
            {endOfRowButtonType &&
                endOfRowButtonType === "plus" ?
                <AddIcon disabled={endOfRowButtonDisabled} onClick={endOfRowButtonFunction || (() => { })} /> :
                endOfRowButtonType === "save" ?
                    <Button disabled={endOfRowButtonDisabled} onClick={endOfRowButtonFunction || (() => { })} text={endOfRowButtonText || ""} /> :
                    null
            }
        </div>
    )
}

// generic checkbox component
export const CheckBox: React.FC<CheckboxProps> = ({ value, onChange, name, id }: CheckboxProps) => {
    return (
        <span className="label-checkbox">
            <input className="checkbox" type="checkbox" checked={value} id={id} name={id} onChange={onChange} />
            <label className="checkbox-label" id={name}>{name}</label><br></br>
        </span>
    )
}

// generic radio button component
export const RadioButton: React.FC<RadioButtonProps> = ({ value, onChange, name, id }: RadioButtonProps) => {
    return (
        <span className="label-checkbox">
            <label>
                <input className="radio" type="radio" checked={value === id} id={id} name={id} onChange={onChange} />
                {name}
            </label>
            {/* <label className="checkbox-label" id={name}>{name}</label><br></br> */}
        </span>
    )
}

// generic FontAwesome icon component
export const GenericIcon: React.FC<IconProps> = ({ icon, onClick, color, disabled, style, className }: IconProps) => {
    return <div className={className || "license-any-icon"} >
        <FontAwesomeIcon
            style={{
                opacity: color ? '1' : "0.6",
                ...style
            }}
            className={'close-icon'}
            onClick={!disabled ? onClick : () => { }}
            icon={["fas", icon]}
        />
    </div>
}