import { loginExtraData, keptForm, loginForm, loginField } from './../interfaces/Login.interface';
import { errorMassagesType } from './validation';

export const EMPTY_KEPT_FORM: keptForm = {
    email: '',
    psw: '',
    verifyPsw: '',
    code: ''
}

const email: loginField = { name: 'email', placeHolder: 'דואר אלקטרוני', show: false, value: '', icon: false, type: 'text' };
const psw: loginField = { name: 'psw', placeHolder: 'סיסמה', show: false, value: '', icon: true, type: 'password' };
const verifyPsw: loginField = { name: 'verifyPsw', placeHolder: 'אימות סיסמה', show: false, value: '', icon: true, type: 'password' };
const code: loginField = { name: 'code', placeHolder: 'קוד', show: false, value: '', icon: false, type: 'text' }

export const EMPTY_LOGIN_FORM: loginForm = {
    email,
    psw
}

export const EMPTY_LOGIN_RESTORE_FORM: loginForm = {
    email
}

export const EMPTY_RESTORE_FORM: loginForm = {
    psw,
    verifyPsw
}

export const EMPTY_CODE_FORM: loginForm = {
    code
}

export const CODE_EXTRA: loginExtraData[] = [
    {
        id: 'new-code',
        text: 'לא קיבלת את הקוד? לחץ ',
        linkText: ' כאן'
    },
    {
        id: 'user-psw',
        text: 'לחזרה אחורה לחץ ',
        linkText: ' כאן'
    }
];

export const RESTORING_EXTRA: loginExtraData[] = [
    {
        id: 'user-psw',
        text: 'לחזרה לעמוד ההתחברות לחץ ',
        linkText: ' כאן'
    }
];

export const LOGIN_EXTRA: loginExtraData[] = [
    {
        id: 'restore',
        text: 'שכחת סיסמה? ',
        linkText: ' לשחזור סיסמה'
    },
    {
        id: 'code',
        text: '',
        linkText: 'כבר קיבלתי קוד'
    }
];

export const LOGIN_ERRORS: errorMassagesType = {
    tryLater: 'אירעה שגיאה בשרת. אנא נסו שנית מאוחר יותר',
    unauthorized: 'אינך מורשה להתחבר לאתר',
    wrongPsw: 'סיסמה שגויה',
    wrongUsername: 'שם משתמש שגוי',
    userBlocked: "התבצעו חמישה נסיונות כניסה ברבע שעה האחרונה. אנא נסו שוב עוד כרבע שעה",
    noPhone: 'אין למשתמש מספר פלאפון שמור',

    noUsername: 'שם משתמש חייב להכיל ערך',
    noPsw: "סיסמה חייבת להכיל ערך",
    invalidUsername: 'שם המשתמש אינו תקין',
    shortPsw: 'סיסמה קצרה מדי',
    longPsw: 'סיסמה ארוכה מדי',
    invalidInfo: 'חלק מהמידע שהוזן אינו נכון',
    invalidPassword: "הסיסמא חייבת לכלול לפחות אות אנגלית אחת, מספר אחד ותו מיוחד מהתווים הבאים: @$!%*#?&",
    noCode: "קוד חייב להכיל ערך",
    invalidCode: "קוד יכול להכיל רק מספרים",
    pswDoesntMatches: 'אימות סיסמה חייב להיות זהה לסיסמה',

    incorrectCode: 'הקוד שהזנת שגוי',
    maxAttempts: 'עברת את מספר הנסיונות המותרים. אנא נסו שנית עוד רבע שעה',
    codeHasExpired: 'הקוד פג, יש לשלוח קוד נוסף'
}